class Routes {
  constructor() {
    this.url = process.env.VUE_APP_API_URL;
    this.api_url = this.url + "/api/v1";
    this.language_set = {
      url: "lang"
    };
    //Upload Media
    this.upload_media = {
      url: "upload/media"
    };
    //Authentication routes
    this.firebase_token_save = {
      url: "firebase/token/save"
    };
    this.login = {
      url: "auth/login"
    };
    this.social_login = {
      url: "auth/social/login"
    };
    this.register = {
      url: "auth/register"
    };
    this.logout = {
      url: "auth/logout"
    };
    this.reset_password = {
      url: "auth/password/reset"
    };

    //Currencies
    this.currencies_all = {
      url: "customer/currencies"
    };
    this.currencies_active = {
      url: "customer/currencies/active"
    };

    // Categories
    this.categories_all = {
      url: "customer/preferences/all"
    };
    this.searchProductsByFilter = {
      url: "customer/search/filter"
    };
    this.categories_preferred_joined = {
      url: "customer/preferences/joined"
    };
    this.categories_preferred = {
      url: "customer/preferences"
    };
    this.categories_sync = {
      url: "customer/preferences"
    };

    // HomePage
    this.homepageProductSections = {
      url: "customer/homepage/product-sections"
    };
    this.homepageProductSectionsShow = {
      url: "customer/homepage/product-sections/show"
    };
    this.homepageBanners = {
      url: "customer/homepage/banners"
    };

    // Stories
    this.stories = {
      url: "customer/homepage/stories"
    };

    // Addresses
    this.addresses = {
      url: "customer/user-addresses"
    };
    this.addresses_makePrimary = {
      url: "customer/user-addresses/{address_id}/makePrimary"
    };
    this.addresses_create = {
      url: "customer/user-addresses"
    };
    this.addresses_update = {
      url: "customer/user-addresses/{address_id}"
    };
    this.addresses_show = {
      url: "customer/user-addresses/{address_id}"
    };
    this.addresses_destroy = {
      url: "customer/user-addresses/{address}"
    };

    //Packages
    this.packages_index = {
      url: "customer/packages"
    };
    this.packages_show = {
      url: "customer/packages/{package_id}/show"
    };
    this.packages_update = {
      url: "customer/packages/{package_id}"
    };
    this.save_package_product = {
      url: "customer/packages/{package_id}/products/{product_id}"
    };
    this.package_remove_product = {
      url:
        "customer/packages/{package_id}/package_products/{package_product_id}"
    };

    this.package_upload_prescription = {
      url: "customer/packages/{package_id}/prescriptions"
    };
    // Orders
    this.fetch_cart = {
      url: "customer/cart"
    };
    this.orders = {
      url: "customer/orders"
    };
    this.orders_create = {
      url: "customer/orders"
    };
    this.orders_show = {
      url: "customer/orders/{order_id}"
    };
    this.orders_process_payment = {
      url: "customer/orders/{order_id}/processPayment"
    };
    this.orders_received = {
      url: "customer/orders/{order_id}/received"
    };
    this.orders_track = {
      url: "customer/orders/{order_id}/track"
    };

    // Products
    this.product_show = {
      url: "customer/product/{product_id}"
    };
    this.product_rate = {
      url: "customer/products/{product_id}/rating"
    };

    // Shops
    this.shops_index = {
      url: "customer/shops"
    };
    this.shop_follow_toggle = {
      url: "customer/followable/follow-toggle/shop/{shop_id}"
    };
    this.shop_rate = {
      url: "customer/shops/{shop_id}/rating"
    };
    this.shop_view = {
      url: "customer/shops/{shop_id}"
    };
    this.shop_featured_products = {
      url: "customer/shops/{shop_id}/featuredProducts?paginate={paginate}"
    };
    this.shop_all_products = {
      url: "customer/shops/{shop_id}/products?paginate={paginate}"
    };
    this.search_shop_products = {
      url: "customer/shops/{shop_id}/products?paginate={paginate}&term={term}"
    };
    this.shop_categories_products = {
      url: "customer/shops/{shop_id}/product-categories/{category_id}"
    };
    this.search_categories_products = {
      url:
        "customer/shops/{shop_id}/product-categories/{category_id}?term={term}"
    };

    // Drivers
    this.driver_rate = {
      url: "customer/drivers/{driver_id}/rating"
    };

    //Personal Information

    this.get_profile = {
      url: "customer/profile"
    };
    this.update_profile = {
      url: "customer/profile/edit"
    };
    this.change_password = {
      url: "customer/profile/password/edit"
    };
    this.verify_code_number = {
      url: "verify/phone/validate"
    };
    this.verify_number = {
      url: "verify/phone/send"
    };

    //Dispute

    this.open_dispute = {
      url: "customer/orders/{id}/dispute"
    };
    this.view_dispute = {
      url: "customer/orders/{id}/dispute"
    };
    this.respond_solution = {
      url: "customer/orders/{orderId}/dispute/solution/{solutionId}/respond"
    };
    this.send_message = {
      url: "customer/orders/{orderId}/dispute/message"
    };
  }

  getUrl(path = "") {
    return this.url + path;
  }

  getImage(image = "", size = "preview", default_src = true) {
    if (image != null) {
      return this.getUrl(image[size]);
    }

    if (default_src == true) {
      return "/images/default-placeholder.png";
    }
    return default_src;
  }

  //Authentication routes
  getRoute(route, parameters = []) {
    //Replace URL {}
    let routeUrl = this[route].url;

    //Find and replace attributes in string
    for (const attribute in parameters) {
      routeUrl = routeUrl.replaceAll(
        "{" + attribute + "}",
        parameters[attribute]
      );
    }

    return this.api_url + "/" + routeUrl;
  }
}

module.exports = Routes;
