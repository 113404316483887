<template>
  <div class="navButton" v-bind:class="{ active: active }">
    <div
      class="button Aligner"
      @click.prevent="onClick"
      v-bind:class="{ arrowless: arrowless }"
    >
      <!-- v-click-away="onClickAway" -->
      <div class="buttonWrapper">
        <slot name="button"></slot>
      </div>
    </div>
    <!-- /.button -->

    <overlay
      v-bind:active="active"
      @overlayClicked="closeMenu"
      style="top: 80px"
    >
      <slot name="dropdown"></slot>
    </overlay>

    <!-- /.dropdown -->
  </div>
  <!-- /.navButton -->
</template>

<script>
import Overlay from "@/components/Overlay.vue";
export default {
  components: { Overlay },
  name: "NavButton",
  props: ["active", "menuName", "arrowless"],
  methods: {
    onClick(e) {
      e.stopPropagation();
      this.$emit("navButton-clicked", this.menuName);
    },
    closeMenu() {
      this.$emit("navButton-clicked", null);
    },
  },
};
</script>

<style lang='scss'>
$NavButtonDropdown_maxHeight: 500px;
$NavButtonDropdown_minWidth: 300px;
$NavButtonDropdown_maxWidth: 400px;
$navButton_bgColor: #f4f7f7;

.navButton {
  width: auto;
  position: relative;

  .button {
    width: auto;
    height: 100%;
    padding-left: map-get($spacings, "medium");
    padding-right: map-get($spacings, "medium") + 15px; //Padding + size of arrow
    min-height: 80px;
    position: relative;

    .buttonWrapper {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: map-get($spacings, "medium") * -1;
        width: 10px;
        height: 10px;
        transform-origin: center center;
        background-image: url(~@/assets/arrow_down.svg);
        background-repeat: no-repeat;
        background-position: center;
        // transition: transform 0.1s ease-in;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:after {
      content: "";
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: map-get($colors, "twiks");
    }

    &.arrowless {
      padding-right: map-get($spacings, "medium");

      .buttonWrapper {
        &:before {
          display: none;
        }
      }
    }

    .title {
      width: 100%;
      float: left;
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: auto;
    background-color: map-get($colors, "white");
    border: 1px solid map-get($defaults, "border-color");
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0 0 map-get($defaults, "border-radius")
      map-get($defaults, "border-radius");
    min-width: $NavButtonDropdown_minWidth;
    max-width: $NavButtonDropdown_maxWidth;
    padding: map-get($spacings, "medium") map-get($spacings, "large");

    &.drop-left {
      left: auto;
      right: 0;
    }

    &.drop-right {
      left: 0;
      right: auto;
    }

    .ps {
      max-height: $NavButtonDropdown_maxHeight;
    }

    ul {
      width: 100%;
      float: left;
      padding: 0;
      margin: 0;
      li {
        position: relative;
        width: 100%;
        float: left;
        list-style: none;
        padding-top: map-get($spacings, "small");
        padding-bottom: map-get($spacings, "small");
        border-bottom: 1px solid map-get($defaults, "border-color");

        .form-check {
          width: 100%;
          label {
            margin-right: 0;
            max-width: 100%;
          }
        }

        a {
          color: map-get($colors, "black");
          text-decoration: none;
        }

        &:hover {
          cursor: pointer;
        }

        .icon {
          vertical-align: middle;
          width: 22px;
          height: 22px;
          margin-right: map-get($spacings, "default");
          object-fit: contain;
          margin-top: -3px;
        }

        &.hasArrow {
          padding-right: map-get($spacings, "default") + 10px;
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            right: 0px;
            width: 10px;
            height: 10px;
            background-image: url(~@/assets/arrow_down.svg);
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center;
          }
        }

        &.hasLink {
          padding-right: 0;
          padding-top: 0;
          padding-bottom: 0;
          a {
            display:block;
            width:100%;
            float:left;
            padding-right: map-get($spacings, "default") + 10px;
            padding-top: map-get($spacings, "small");
            padding-bottom: map-get($spacings, "small");
          }
        }

        &:first-of-type {
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }

  &.active {
    .button {
      //background-color: $navButton_bgColor;

      .buttonWrapper {
        &:before {
          transform: rotate(180deg) translateY(50%);
        }
      }

      &:after {
        display: block;
      }
    }

    .dropdown {
      display: block;
    }
  }
}

@include media-breakpoint-down(lg) {
  .navButton {
    .button {
      padding-left: map-get($spacings, "default");
      padding-right: map-get($spacings, "default") + 15px; //Padding + size of arrow

      &.arrowless {
        padding-right: map-get($spacings, "default");
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navButton {
    .button {
      min-height: map-get($defaults, "header-height-mobile");
    }
  }
}
</style>
