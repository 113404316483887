const state = {
	primaryAddress: localStorage.getItem("primaryAddress") !== undefined ? JSON.parse(localStorage.getItem("primaryAddress")) : null,
	addresses: [],
};

const getters = {
	primaryAddress: (state) => state.primaryAddress,
	addresses: (state) => state.addresses,
	getAddressById: (state) => (address_id) => {
		return state.addresses.filter((address) => {
			return address.id === address_id;
		})[0];
	},
};

const actions = {
	async getAddress({ commit }, address_id) {
		return new Promise((resolve, reject) => {
			window.axios
				.get(
					window.Routes.getRoute("addresses_show", { address_id: address_id })
				)
				.then(
					(response) => {
						resolve(response.data.data);
					},
					(error) => {
						commit("setErrors", [error.response.data.errors]);
						reject(error.response.data.errors);
					}
				);
		});
	},
	async editAddress({ commit }, { address_id, address }) {
		return new Promise((resolve, reject) => {
			window.axios
				.put(
					window.Routes.getRoute("addresses_update", {
						address_id: address_id,
					}),
					address
				)
				.then(
					(response) => {
						let address = response.data.data;
						commit("editAddress", address);
						if (address.primary == true) {
							commit("setPrimaryAddress", address.id);
						}
						resolve(response.data.data);
					},
					(error) => {
						commit("setErrors", [error.response.data.errors]);
						reject(error.response.data.errors);
					}
				);
		});
	},
	async getAddresses({ commit }) {
		await window.axios.get(window.Routes.getRoute("addresses")).then(
			(response) => {
				commit("setAddresses", response.data.data);
			},
			(error) => {
				commit("setErrors", [error.response.data.errors]);
			}
		);
	},
	async setPrimaryAddress({ commit }, address_id) {
		//Update primary address
		commit("setPrimaryAddress", address_id);
		await window.axios
			.post(
				window.Routes.getRoute("addresses_makePrimary", {
					address_id: address_id,
				})
			)
			.catch((error) => {
				commit("setErrors", [error.response.data.errors]);
			});
	},
	async createAddress({ commit }, addressObject) {
		return new Promise((resolve, reject) => {
			window.axios
				.post(window.Routes.getRoute("addresses_create"), addressObject)
				.then(
					(response) => {
						let address = response.data.data;
						commit("addAddress", address);
						//Check if this adddress is primary
						if (address.primary == true) {
							commit("setPrimaryAddress", address.id);
						}
						resolve(address);
					},
					(error) => {
						commit("setErrors", [error.response.data.errors, "createAddress"]);
						reject(error.response.data.errors);
					}
				);
		});
	},
	async deleteAddress({ commit }, address) {
		await window.axios
			.delete(window.Routes.getRoute("addresses_destroy", { address: address }))
			.then(
				() => {
					commit("deleteAddress", address);
				},
				(error) => {
					commit("setErrors", [error.response.data.errors]);
				}
			);
	},
};

const mutations = {
	setAddresses: (state, addresses) => {
		//Find selected address
		addresses.forEach((address) => {
			if (address.primary === true) {
				state.primaryAddress = address;
				localStorage.setItem("primaryAddress", JSON.stringify(address));
				return;
			}
		});

		state.addresses = addresses;
	},
	editAddress: (state, updated_address) => {
		const index = state.addresses.findIndex(
			(address) => address.id == updated_address.id
		);
		if (index !== -1) {
			state.addresses.splice(index, 1, updated_address);
		}
	},
	addAddress: (state, address) => {
		state.addresses.push(address);
	},
	deleteAddress: (state, address_id) => {
		state.addresses = state.addresses.filter(
			(filtered_address) => filtered_address.id != address_id
		);
	},

	setPrimaryAddress: (state, address_id) => {
		const primary_index = state.addresses.findIndex((address_element) => {
			return address_element.id == address_id;
		});

		state.primaryAddress = state.addresses[primary_index];

		localStorage.setItem("primaryAddress", JSON.stringify(state.primaryAddress));

		//Update addresses
		state.addresses.map(function(address) {
			if (address.id !== address_id) {
				address.selected = false;
			} else {
				address.selected = true;
			}
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
