<template>
  <div class="accountDropdown">
    <div class="dropdown drop-left">
      <div class="row dropdown_columns">
        <div class="column d-none d-md-block col-6 active_orders">
          <div class="title row font-size-big pb-2 font-weight-bold">
            <div class="col">{{ $t("orders.active_orders") }}</div>
            <div class="col-auto color-twiks">
              {{ active_orders_meta.total }}
            </div>
          </div>
          <div class="body">
            <infinite-scrolling
              @endReached="activeOrdersScrollEnded"
              v-bind:loading="loading"
            >
              <order-card-slim
                v-for="order in active_orders"
                v-bind:key="order.id"
                v-bind:order="order"
              />
            </infinite-scrolling>
          </div>
          <!-- /.body -->
        </div>
        <!-- /.active_orders -->
        <div class="column col-md-6 account_menu">
          <div class="body">
            <perfect-scrollbar :options="{ wheelPropagation: false }">
              <div class="grouped">
                <div class="title font-size-big pb-2 font-weight-bold">
                  {{ $t("nav.my_account") }}
                </div>

                <ul>
                  <li class="hasArrow hasLink">
                    <router-link to="/orders">
                      <img class="icon" src="@/assets/nav/orders.svg" />
                      {{ $t("orders.title") }}
                    </router-link>
                  </li>
                  <router-link class="router" to="/packages">
                    <li class="hasArrow hasLink">
                      <img class="icon" src="@/assets/nav/packages.svg" />
                      {{ $t("packages.title") }}
                    </li>
                  </router-link>
                </ul>
              </div>
              <div class="grouped">
                <ul>
                  <li class="hasArrow hasLink">
                    <router-link to="/profile">
                      <img class="icon" src="@/assets/nav/personal_info.svg" />
                      {{ $t("users.personal_info") }}
                    </router-link>
                  </li>
                  <li class="hasArrow hasLink">
                    <router-link to="/addresses">
                      <img class="icon" src="@/assets/nav/addresses.svg" />
                      {{ $t("addresses.manager_address_book") }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="grouped">
                <ul>
                  <li class="hasArrow" @click="logout">
                    <img class="icon" src="@/assets/nav/logout.svg" />
                    {{ $t("auth.logout") }}
                  </li>
                </ul>
              </div>
            </perfect-scrollbar>
          </div>
          <!-- /.body -->
        </div>
        <!-- /.account_menu -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InfiniteScrolling from "@/components/InfiniteScrolling.vue";
import OrderCardSlim from "@/components/Orders/OrderCardSlim.vue";

export default {
  components: { OrderCardSlim, InfiniteScrolling },
  name: "NavButtonAccountDropdown",
  props: ["style"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["authLogout", "getActiveOrders"]),
    deleteCurrentFirebaseToken() {
      //Check if Firebase Messaging is supported by the browser
      if(this.$messaging !== null){
        this.$messaging.deleteToken().then(
            (success) => {
              console.log("Token delete: " + success);
            },
            (error) => {
              console.log("Token delete: " + error);
            }
        );
      }
    },
    logout() {
      this.deleteCurrentFirebaseToken();
      this.authLogout(localStorage.access_token);
    },
    activeOrdersScrollEnded() {
      this.loading = true;
      this.getActiveOrders(this.active_orders_meta.current_page + 1).then(
        () => {
          {
            this.loading = false;
          }
        }
      );
    },
  },
  computed: {
    ...mapGetters(["user", "active_orders", "active_orders_meta"]),
  },
};
</script>

<style lang="scss">
$navButton_accountDropdown_width: 600px;

.accountDropdown {
  .dropdown {
    padding: 0;
    width: $navButton_accountDropdown_width;
    max-width: $navButton_accountDropdown_width;
    overflow: hidden;

    .dropdown_columns {
      margin: 0;

      .column {
        padding: 0;

        &.active_orders {
          box-shadow: 0px 3px 6px #00000029;
          background-color: map-get($colors, "lightGray");
          padding: map-get($spacings, "medium") map-get($spacings, "large");
          padding-bottom: 0;

          .card {
            .card-header {
              background-color: map-get($colors, "white");
            }
          }
        }

        &.account_menu {
          .ps {
            padding-top: map-get($spacings, "medium");

            .grouped {
              width: 100%;
              float: left;
              padding: map-get($spacings, "small") map-get($spacings, "large");
              border-bottom: 10px solid map-get($defaults, "border-color");

              ul {
                li {
                  line-height: 30px;
                  position: relative;

                  a {
                    display:block;
                  }

                  .counter {
                    float: right;
                  }
                }
              }

              &:first-of-type {
                padding-top: 0;
              }

              &:last-of-type {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .overlayWrapper.active {
    .accountDropdown {
      position: absolute;
      top: map-get($defaults, "header-height-mobile");
      left: 0;
      width: 100%;
      max-width: none;

      .dropdown {
        padding: 0;
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .overlayWrapper.active {
    .accountDropdown {
      position: fixed;
      width: 100%;
      top: map-get($defaults, "header-height-mobile");
      left: 0;
      right: 0;
      bottom: 0;

      .dropdown {
        position: relative;
        top: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        float: left;
        max-width: none;
        overflow: hidden;

        .dropdown_columns {
          height: 100%;

          .column {
            height: 100%;

            &.account_menu {
              .body {
                height: 100%;

                .ps {
                  height: 100%;
                  max-height: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
