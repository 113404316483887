const state = {
  latitude: null,
  longitude: null,
};

const getters = {
  latitude: (state) => state.latitude,
  longitude: (state) => state.longitude,
  geolocation: (state) => {
    if (state.latitude || state.longitude == null) {
      return null;
    }
    return {
      latitude: state.latitude,
      longitude: state.longitude,
    };
  },
};

const actions = {
  // async getAddresses({ commit }) {
  //   await window.axios.get(window.Routes.getRoute("addresses")).then(
  //     (response) => {
  //       commit("setAddresses", response.data.data);
  //     },
  //     (error) => {
  //       commit("setErrors", [error.response.data.errors]);
  //     }
  //   );
  // },
};

const mutations = {
  // setAddresses: (state, addresses) => {
  //   //Find selected address
  //   addresses.forEach((address) => {
  //     if (address.primary == true) {
  //       state.primaryAddress = address;
  //       return;
  //     }
  //   });
  //   state.addresses = addresses;
  // },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
