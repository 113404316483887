<template>
  <div id="notifications" v-show="notifications.length !== 0">
    <perfect-scrollbar :options="{wheelPropagation: false}">
      <notification-item :class="{last : index === notifications.length - 1}" v-for="(notification, index) in notifications" :key="index" :notification="notification"/>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {checkNotificationPermissions} from "@/includes/browser_permissions";
import {checkToken} from "@/includes/firebase_fcm";
import NotificationItem from "./NotificationItem";

export default {
  name: "Notifications",
  components: {NotificationItem},
  computed: {
    ...mapGetters(['notifications'])
  },
  methods: {
    ...mapActions(['createNotification', 'enableNotificationCleaner']),
    onNotification(){
      //TODO: run this function again on login
      //TODO: On logout delete token -> send request to serve
      if(this.access_token !== null){
        checkNotificationPermissions().then((permission) => {
          console.log("Notification permissions: "  + permission);
          if (permission === true) {
            //Enable notification cleaner
            this.enableNotificationCleaner();

            // Check if Firebase Messaging is supported by the browser
            if(this.$messaging !== null){
              this.$messaging.getToken({vapidKey: window.vapidKey})
                  .then(token => {
                    console.log("Firebase token : " + token);
                    if (token) {
                      checkToken(token);

                      //On Background
                      navigator.serviceWorker.addEventListener('message', event => {
                        let payload = event.data;
                        // eslint-disable-next-line no-unused-vars
                        let data = payload.data;
                        let notification = payload.notification;

                        //Create notification
                        this.createNotification(payload);

                        //Check notification type
                        if(payload.messageType === "push-received"){
                          //Notification received while tab is active
                          //Display Notification in browser
                          let noteTitle = notification.title;
                          let noteOptions = {
                            body: notification.body,
                            icon: notification.image,
                          };
                          /** This Notifications API interface is used to configure and display desktop notifications to the user. */
                          new Notification(noteTitle, noteOptions);
                        } else {
                          //Notification clicked
                        }
                      })
                    } else {
                      // Show permission request. UI.
                      console.log(
                          "No Instance ID token available. Request permission to generate one."
                      );
                      //Clear the localStorage
                      localStorage.removeItem("firebase_token");
                    }
                  });
            }

          } else {
            // Show permission request. UI.
            //Clear the localStorage
            localStorage.removeItem("firebase_token");
          }
        });
      }
    }
  },
  mounted() {
    this.onNotification();
  },
}
</script>

<style lang="scss" scoped>
 #notifications {
   z-index:9999;
   width:100%;
   max-width: 350px;
   height:auto;
   top:90px;
   right:20px;
   position:fixed;

   .ps {
     padding:10px;
     width:100%;
     height:auto;
     max-height:600px;
   }

 }
</style>
