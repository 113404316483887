<template>
  <div id="footer">
    <div class="top">
      <div class="container">
        <div class="row">
          <div class="col-6 col-md-3" v-if="access_token !== null">
            <ul>
              <li class="font-weight-bold font-size-md">
                {{ $t("footer.my_account") }}
              </li>
              <li>
                <router-link to="/orders">
                  {{ $t("orders.title") }}
                </router-link>
              </li>
              <li>
                <router-link class="router" to="/packages">
                  {{ $t("packages.title") }}
                </router-link>
              </li>
              <li>
                <router-link to="/addresses">
                  {{ $t("addresses.manager_address_book") }}
                </router-link>
              </li>
              <li>
                <router-link to="/profile">
                  {{ $t("users.personal_info") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-3" v-if="access_token !== null">
            <ul>
              <li class="font-weight-bold font-size-md">
                {{ $t("languages.language") }}
              </li>
              <li>
                <nav-button-languages-select/>
              </li>
              <li class="font-weight-bold font-size-md">
                {{ $t("currencies.currency") }}
              </li>
              <li>
                <nav-button-currency-select/>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-3">
            <ul>
              <li class="font-weight-bold font-size-md">
                {{ $t("footer.about_twiks") }}
              </li>
              <li>
                <router-link :to="'/pages/' + $i18n.locale + '/privacy-policy'">
                  {{ $t("pages.privacy_policy.title") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-6 col-md-3">
            <ul>
              <li class="font-weight-bold font-size-md">
                {{ $t("footer.stay_connected") }}
              </li>
              <li>
                <div class="row social_links">
                  <div class="col-auto" v-if="env.VUE_APP_FACEBOOK_LINK">
                    <a :href="env.VUE_APP_FACEBOOK_LINK">
                      <i class="fab fa-facebook"></i>
                    </a>
                  </div>
                  <div class="col-auto" v-if="env.VUE_APP_TWITTER_LINK">
                    <a :href="env.VUE_APP_TWITTER_LINK">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </div>
                  <div class="col-auto" v-if="env.VUE_APP_INSTAGRAM_LINK">
                    <a :href="env.VUE_APP_INSTAGRAM_LINK">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-auto copyright">
            {{ $t("footer.copyright", {'year': year}) }}
          </div>
          <div class="col-auto app_markets">
            <a :href="env.VUE_APP_APP_STORE_LINK" v-if="env.VUE_APP_APP_STORE_LINK">
              <img src="@/assets/footer/app_store_icon.png" alt="App Store">
            </a>

            <a :href="env.VUE_APP_PLAY_STORE_LINK" v-if="env.VUE_APP_PLAY_STORE_LINK">
              <img src="@/assets/footer/google_play_icon.png" alt="Google Play">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavButtonLanguagesSelect from "@/views/Layout/partials/Nav/NavButtonLanguagesSelect";
import NavButtonCurrencySelect from "@/views/Layout/partials/Nav/NavButtonCurrencySelect";
import {mapGetters} from "vuex";

export default {
  name: "Footer",
  components: {NavButtonCurrencySelect, NavButtonLanguagesSelect},
  data() {
    return {
      year: new Date().getFullYear(),
      env: {
        //App Stores
        VUE_APP_APP_STORE_LINK: process.env.VUE_APP_APP_STORE_LINK,
        VUE_APP_PLAY_STORE_LINK: process.env.VUE_APP_PLAY_STORE_LINK,
        //Social Links
        VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
        VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
        VUE_APP_TWITTER_LINK: process.env.VUE_APP_TWITTER_LINK,
      }
    }
  },
  computed: {
    ...mapGetters(["access_token"]),
  },
};
</script>

<style lang='scss' scoped>
#footer {
  background-color:#fff;
  position:relative;
  .top {
    background-color: map-get($colors, "gray");
    padding: map-get($spacings, "seperator") 0;

    ul {
      width: 100%;
      float: left;
      padding-left:0;

      li {
        width: 100%;
        float: left;
        list-style-type: none;
        margin-bottom: map-get($spacings, "small");

        a {
          color: map-get($colors, "black");
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .social_links {
      a {
        font-size: 22px;
      }
    }
  }

  .bottom {
    position: relative;
    background-color:#fff;
    //Display it on top of near you button
    z-index:10;
    padding: map-get($spacings, "seperator") 0;

    .copyright {
      display: flex;
      align-items: center;
    }

    .app_markets {
      display: flex;
      a {
        margin-left:5px;
        img {
          height:40px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #footer {
    .bottom {
      .app_markets {
        margin-top: map-get($spacings, "default");
        a {
          margin-left:0;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
