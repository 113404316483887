function MiddlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return param => {
    if (param) return context.next(param)

    const nextPipeline = MiddlewarePipeline(
        context, middleware, index + 1
    )

    nextMiddleware({ ...context, next: nextPipeline })
  }
}

export default MiddlewarePipeline;
