<template>
  <div @click.stop='openNotification(notification)' class="notification">
    <div class="body row g-0">
      <!-- Close Btn -->
      <div @click.stop='closeNotification(notification.id)' class="close Aligner">
        <i class="fas fa-times-circle"></i>
      </div>

      <div class="image col-auto Aligner">
        <div class="icon Aligner">
          <i class="far fa-bell"></i>
        </div>
      </div>
      <div class="col body">
        <div class="row g-0 mb-1">
          <div class="col title font-weight-bold pe-1">
            {{notification.title}}
          </div>
          <div class="col-auto">
            {{moment(notification.created_at).format("LT")}}
          </div>
        </div>
        <div class="body font-size-sm">{{notification.body}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "NotificationItem",
  props: {
    notification: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    ...mapActions(['deleteNotification']),
    closeNotification(notification_id){
      this.deleteNotification(notification_id);
    },
    openNotification(notification) {
      switch (notification.model) {
        //Redirects
        case "order" :
          this.$router.push("/orders/" + notification.model_id);
          break;
        case "package" :
          this.$router.push("/packages/" + notification.model_id);
          break;
        case "product" :
          this.$router.push("/products/" + notification.model_id);
          break;
        case "shop" :
          this.$router.push("/shops/" + notification.model_id);
          break;
      }
      this.deleteNotification(notification.id);
    },
  }
}
</script>

<style lang="scss" scoped>
.notification {
  width:100%;
  float:left;
  padding: map-get($spacings, "large") map-get($spacings, "medium");
  box-shadow: rgba(#000, 0.25) 0px 0px 7px;
  background-color: map-get($colors, 'white');
  border-radius: map-get($defaults, "border-radius");
  margin-bottom: map-get($spacings, "small");
  position: relative;

  .close {
    position:absolute;
    top:-7px;
    right:-8px;
    width:20px;
    height:20px;
    font-size: 20px;
    border-radius:20px;
    color: map-get($colors, "secondary");
    background-color:#fff;
    display:none;
  }

  & > .body {
    & > .image {
      padding-right: map-get($spacings, "default");

      .icon {
        width:40px;
        height:40px;
        border:1px solid map-get($defaults, "border-color");
        border-radius: map-get($defaults, "border-radius");
        font-size: 20px;
        color: map-get($colors, "secondary");
      }
    }

    & > .title {
      padding-right:60px;
    }

    & > .body {

    }
  }

  &:hover {
    cursor: pointer;

    .close {
      display: block;
    }
  }

  &.last{
    border-bottom:0;
    margin-bottom:0;
  }
}
</style>
