const checkNotificationPermissions = async function () {
    return new Promise((resolve) => {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            resolve(false);
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            resolve(true);
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        }

        resolve(false);
    });
}

export {
    checkNotificationPermissions
}
