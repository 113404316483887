import {Promise} from "core-js";

const state = {
	guestAddress: localStorage.getItem("guestAddress") !== undefined ? JSON.parse(localStorage.getItem("guestAddress")) : null,
};

const getters = {
	guestAddress: (state) => state.guestAddress,
};

const actions = {
	async setGuestAddress({ commit }, addressObject) {
		return new Promise(resolve => {
			commit("SET_GUEST_ADDRESS", addressObject);
			resolve(addressObject);
		});
	},
};

const mutations = {
	SET_GUEST_ADDRESS: (state, address) => {
		localStorage.setItem("guestAddress", JSON.stringify(address))
		state.guestAddress = address;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
