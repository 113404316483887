import { createApp } from "vue";
import App from "./views/Layout/App.vue";
import store from "./store";
import router from "./router";
import RoutesClass from "@/includes/variables/routes";
import Axios from "axios";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { i18n, language } from "@/includes/i18n";
import VueGeolocation from "vue3-geolocation";
import GMaps from "vuejs3-google-maps";
import "@/includes/vee-validate-rules";
import moment from "moment";
import { initialize } from "@/includes/firebase_fcm";
import { createMetaManager } from 'vue-meta'

//FIREBASE CONFIGURATION
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};
window.vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY;

// INITIALIZE FIREBASE
let firebase_messaging = initialize(firebaseConfig);

window.axios = Axios;
window.Routes = new RoutesClass();

//Set Axios defaults
window.axios.defaults.headers["Content-Language"] = language;
window.axios.defaults.headers["X-Display-Currency"] =
  localStorage.getItem("currency") ?? null;
window.axios.defaults.headers["Accept"] = "application/json";
//Set document language attribute
document.documentElement.lang = language;
//Set moments locale
moment.locale(language);

let app = createApp(App);
app.use(VueGeolocation);
app.use(GMaps, {
  load: {
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  },
});

app.directive("click-away", {
  beforeMount(el, binding) {
    el.clickAwayEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickAwayEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickAwayEvent);
  },
});

const metaManager = createMetaManager();

app
  .use(PerfectScrollbar)
  .use(router)
  .use(store)
  .use(metaManager)
  .use(i18n);

router.isReady().then(() => app.mount('#app'))

app.config.globalProperties.window = window;
app.config.globalProperties.$messaging = firebase_messaging;
app.config.globalProperties.moment = moment;
