<template>
  <div class="card">
    <router-link v-bind:to="'/orders/' + order.id">
      <div class="card-header">
        <div class="row">
          <div class="col color-twiks">
            <div class="font-size-default dotdotdot lines-4">
              <img class="shop_icon" src="@/assets/nav/shop.svg" />
              {{ order.shop.name }}
            </div>
          </div>
          <div class="col-auto color-black">{{ formated_price }}</div>
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="card-text color-black font-weight-bold">
          {{ $t("orders.status." + order.status) }}
        </div>
        <div class="card-text color-secondary">
          {{ order_products_count }}
          {{
            order_products_count == 1
              ? $t("products.show.title")
              : $t("products.title")
          }}
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import formatPrice from "@/includes/currency";
export default {
  name: "OrderCardSlim",
  props: {
    order: {
      type: Object,
    },
  },
  computed: {
    order_products_count: function () {
      return Object.keys(this.order.ordered_products).length;
    },
    formated_price: function () {
      return formatPrice(this.order.currency, this.order.total_amount);
    },
  },
};
</script>

<style lang='scss' scoped>
.card {
  margin-bottom: map-get($spacings, "small");
  .card-header {
    .shop_icon {
      height: 15px;
      margin-right: map-get($spacings, "xsmall");
      margin-top: -5px;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
