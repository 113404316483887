import { language } from "@/includes/i18n";

const state = {
  language: language,
  languages: {
    en: "English",
  },
};

const getters = {
  language: (state) => state.language,
  languages: (state) => state.languages,
};

const actions = {
  async setLanguage({ commit }, language) {
    return new Promise((resolve, reject) => {
      window.axios
          .put(window.Routes.getRoute("language_set"), {lang: language})
          .then(
              () => {
                commit("setLanguage", language);
                resolve(language);
              },
              (error) => {
                commit("setErrors", [error.response.data.errors]);
                reject(error.response.data.errors);
              }
          );
    });
  },
};

const mutations = {
  setLanguage: (state, language) => {
    state.language = language;
    localStorage.setItem("language", language);
    //Set document language attribute
    document.documentElement.lang = language;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
