<template>

  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `${content} | ${siteName}` : siteName }}
    </template>
  </metainfo>

  <Nav v-if="this.$route.meta.showNav !== false"/>

  <div class="container">
    <Errors v-if="this.$route.meta.showErrors !== false"/>

    <Notifications v-if="access_token != null"/>
  </div>

  <router-view/>

  <div class="clear"></div>

  <Footer v-if="this.$route.meta.showFooter !== false"/>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Errors from "@/components/Errors.vue";
import Nav from "./partials/Nav/Nav.vue";
import Footer from "./partials/Footer.vue";
import Notifications from "./partials/Notifications/Notifications";
import {useMeta} from "vue-meta";
import {useI18n} from 'vue-i18n'

export default {
  name: "App",
  setup() {
    const {t} = useI18n() // use as global scope

    useMeta({
      title: '',
      htmlAttrs: {lang: 'en', amp: true}
    })

    return {t};
  },
  components: {
    Notifications,
    Nav,
    Errors,
    Footer,
  },
  computed: {
    ...mapGetters(["access_token"]),
  },
  methods: {
    ...mapActions(["checkAccessToken"]),
  },
  created() {
    this.checkAccessToken();
  },
  data() {
    return {
      siteName: process.env.VUE_APP_NAME
    }
  }
};
</script>

<style>
html:lang(ar) {
  text-align: right;
}
</style>

<style lang='scss'>
@import "@/scss/app.scss";
</style>
