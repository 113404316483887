import { Promise } from "core-js";

const state = {
	active_orders: [],
	active_orders_meta: {},
};

const getters = {
	active_orders: (state) => state.active_orders,
	active_orders_meta: (state) => state.active_orders_meta,
};

const actions = {
	async getActiveOrders({ commit }, page = 1) {
		return new Promise((resolve, reject) => {
			let url = window.Routes.getRoute("orders");
			window.axios
				.get(url, {
					params: {
						ordersBy: "desc",
						filter: "active",
						page: page,
					},
				})
				.then(
					(response) => {
						resolve(response.data);
						commit("setActiveOrders", response.data);
						commit("setActiveOrdersMeta", response.data);
					},
					(error) => {
						reject(error.response.data.errors);
						commit("setErrors", error.response.data.errors);
					}
				);
		});
	},
	async makeOrder({commit}, order_data){
		return new Promise( (resolve, reject) => {
			let url = window.Routes.getRoute("orders_create");
			window.axios
				.post(url, order_data)
				.then(
					(response) => {
						commit("addActiveOrder", response.data.data);
						resolve(response.data.data);
					},
					(error) => {
						reject(error.response.data.errors);
						commit("setErrors", error.response.data.errors);
					}
				);
		})
	}
};

const mutations = {
	addActiveOrder: (state, order) => {
		state.active_orders.push(order);
	},
	setActiveOrders: (state, data) => {
		if (data.meta.current_page != 1) {
			state.active_orders = [...state.active_orders, ...data.data];
		} else {
			state.active_orders = data.data;
		}
	},
	setActiveOrdersMeta: (state, data) => {
		state.active_orders_meta = data.meta;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
