<template>
  <select2
      v-model="selected_language"
      class="select2-rounded"
      :options="languagesForSelect2"
      :settings="{templateResult:select2template, templateSelection:select2template, dropdownCssClass: 'select2-rounded'}"
      @select="languageChanged($event)"
  />
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Select2 from "vue3-select2-component";

export default {
  name: "NavButtonLanguagesSelect",
  components: {
    Select2
  },
  data(){
    return {
      selected_language : null,
    }
  },

  watch: {
    language: {
      immediate: true,
      handler(){
        this.selected_language = this.language;
      }
    }
  },
  computed: {
    ...mapGetters(["language", "languages"]),
    languagesForSelect2(){
      let computed_array = [];

      for (const languageKey in this.languages) {
        computed_array.push({
          id: languageKey,
          text: this.languages[languageKey],
          selected: languageKey === this.language
        });
      }

      return computed_array;
    }
  },
  methods: {
    ...mapActions(["setLanguage"]),
    languageChanged(event){
      let language = event.id;
      //If user is logged in
      if (localStorage.getItem("access_token")) {
        this.setLanguage(language).then(() => {
          this.$i18n.locale = language;
          location.reload();
        });
      } else {
        localStorage.setItem("language", language);
        location.reload();
      }
    },
    select2template(state){
      if (!state.id) {
        return state.text;
      }
      let key = state.element.value.toLowerCase();
      //Image URL
      let image_url = require(`@/assets/lang/${key}.png`);
      //Generate Dom Template
      let dom = document.createElement('div');
      dom.classList.add("flag");
      dom.innerHTML = '<img src="'+image_url+'" class="img-flag" /> ' + state.text + '';
      return dom;
    }
  },
}
</script>

<style lang="scss">
.select2-rounded {
  .select2-container {
    width:100% !important;
  }

  .flag {
    display:flex;
    align-items: center;
    img {
      width: 22px;
      margin-right:7px;
    }
  }
}
</style>
