<template>
  <div id="NavButtonAccount">
    <nav-button
      @click.stop=""
      v-bind:menuName="menuName"
      @navButton-clicked="navButtonClicked"
      v-bind:active="activeMenu === menuName"
    >
      <template v-slot:button>
        <div>
          <div class="text d-none d-lg-block">
            <div class="username font-weight-bold">
              {{ $t("nav.hi") }}, {{ user.name }}
            </div>
            <div
              v-if="active_orders_meta.total !== 0"
              class="activeOrders font-size-sm color-twiks text-uppercase"
              v-bind:class="{ empty: active_orders_meta.total === 0 }"
            >
              {{ active_orders_meta.total }}
              {{ $t("orders.active_orders") }}
            </div>
          </div>
          <div class="profile_icon d-block d-lg-none">
            <img src="@/assets/nav/personal_info.svg" />
          </div>
        </div>
      </template>
      <template v-slot:dropdown>
        <nav-button-account-dropdown />
      </template>
    </nav-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavButton from "./NavButton.vue";
import NavButtonAccountDropdown from "./NavButtonAccountDropdown.vue";
export default {
  name: "NavButtonAccount",
  components: { NavButton, NavButtonAccountDropdown },
  props: ["activeMenu", "menuName"],
  computed: {
    ...mapGetters(["user", "active_orders", "active_orders_meta"])
  },
  methods: {
    ...mapActions(["getActiveOrders"]),
    navButtonClicked() {
      this.$emit("navButton-clicked", this.menuName);
    }
  },
  created() {
    this.getActiveOrders();
  }
};
</script>

<style lang="scss">
#NavButtonAccount {
  .loginBtn {
    text-decoration: none;
  }

  .navButton {
    .button {
      padding-right: 0;
      padding-left: 0;
      .buttonWrapper {
        &:before {
          transform: translateY(0%);
          right: 0px;
          top: 4px;
          bottom: 0px;
        }

        .text {
          width: auto;
          height: 100%;
          float: left;
        }
        .username {
          text-align: right;
          padding-right: map-get($spacings, "medium");
        }

        .activeOrders {
          text-align: right;
        }

        .profile_icon {
          width: 30px;
          height: 30px;
          float: left;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    &.active {
      .button {
        .buttonWrapper {
          &:before {
            transform: translateY(0%) rotate(180deg);
            top: 4px;
            bottom: 0px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #NavButtonAccount {
    .loginBtn {
      text-decoration: none;
    }

    .navButton {
      .button {
        .buttonWrapper {
          &:before {
            display: none;
          }
        }
      }

      &.active {
        .button {
          .buttonWrapper {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #NavButtonAccount {
    .navButton {
      position: static;
    }
  }
}

@include media-breakpoint-down(sm) {
  #NavButtonAccount {
    .navButton {
      .button {
        .buttonWrapper {

          .profile_icon {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}


</style>
