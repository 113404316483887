export default function prepareGuestAddressParams(form_data = {}){
  let access_token = localStorage.getItem('access_token');
  let guestAddress = localStorage.getItem('guestAddress') ? JSON.parse(localStorage.getItem('guestAddress')) : null;
  if(access_token === null){
    //Guest user
    //Check if guest address is set
    if(guestAddress != null){
      form_data['latitude'] = guestAddress.latitude;
      form_data['longitude'] = guestAddress.longitude;

      return form_data;
    } else {
      //Guest address not available
    }
  }

  //Authenticated user
  return form_data;
}
