import { createI18n } from "vue-i18n";
import en from "@/assets/lang/en.json";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";

const language =
  localStorage.getItem("language") != null
    ? localStorage.getItem("language")
    : process.env.VUE_APP_DEFAULT_LANGUAGE;

const i18n = new createI18n({
  legacy: false,
  locale: language,
  fallbackLocale: language, // set fallback locale
  messages: {
    en: en,
  },
});

//Vee Validation Rules
configure({
  // Generates an English message locale generator
  generateMessage: localize({
    en: {
      messages: en.validation,
    },
  }),
});

export { i18n, language };
