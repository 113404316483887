<template>
  <li class="address-input-holder">
    <div class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="radio"
        name="address"
        v-bind:id="'location_' + location.id"
        v-bind:value="location.id"
        v-bind:checked="location.id == primaryAddress.id"
        @click="locationClicked(location.id)"
      />
      <label
        class="form-check-label font-size-default"
        v-bind:for="'location_' + location.id"
      >
        <div class="color-secondary">{{ location.country.name }}, {{ location.city }}</div>
        <div class="font-size-md dotdotdot lines-2">{{ location.address_description }}</div>
      </label>
    </div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NavButtonAddressesRadioButton",
  props: ["location"],
  methods: {
    ...mapActions(["setPrimaryAddress"]),
    locationClicked(locationId) {
      this.setPrimaryAddress(locationId);
    },
  },
  computed: {
    ...mapGetters(["primaryAddress"]),
  },
};
</script>

<style lang='scss' scoped>
li.address-input-holder {
  .form-check {
    .form-check-input {
      & + label {
        margin-top: 3px;
      }
    }
  }
}

</style>
