/*
    Cart BLOCK represents an object of the cart, that is grouped by a shop id and the delivery type
    Cart has multiple blocks
*/

// import momentTimezone from "moment-timezone";

import momentTimezone from "moment-timezone";

const state = {
    cart: JSON.parse(localStorage.getItem("cart")) ?? [],
};

const getters = {
    cart: (state) => state.cart,
    getGroupById: (state) => (group_id) => {
        let group = state.cart.filter(group => {
            return group.id === group_id;
        });

        if (group.length !== 0) {
            return group[0];
        }

        return null;
    },
    cartProductsCounter: (state) => {
        let counter = 0;
        //Get Cart
        let cart_object = state.cart;
        //Check if cart is empty
        if (cart_object.length !== 0) {
        //Go through each cart group
            for (const block of cart_object) {
                //Add nr of products to counter
                counter += block.products.length;
            }
        }

        return counter;
    }
};

const actions = {
    add_to_cart({commit}, {product, cart_data}) {
        //Filter cart data extras
        let filtered_product_extras = {};
        for (const key in cart_data.product_extras) {
            if (cart_data.product_extras[key] > 0) {
                filtered_product_extras[key] = cart_data.product_extras[key];
            }
        }
        cart_data.product_extras = filtered_product_extras;

        commit('addBlockToCart', {product, cart_data});
    },
    save_cart_to_storage({state}) {
        //Save to storage
        let cart_object = state.cart;
        localStorage.setItem("cart", JSON.stringify(cart_object));
    },
    removeGroupFromCart({commit}, group_id) {
        commit('removeGroup', group_id);
    },
    removeProductFromGroup({commit}, {group_id, product_id}){
        commit('removeProduct', {group_id, product_id});
    },
    setGroupPaymentMethod({commit}, {group_id, payment_method}) {
        commit('updateGroupPaymentMethod', {
            "group_id": group_id,
            "payment_method": payment_method
        });
    },
    setGroupAddressId({commit}, {group_id, address_id}) {
        commit('updateGroupAddressId', {
            "group_id": group_id,
            "address_id": address_id
        });
    },
    emptyCart({commit}) {
        commit('emptyCart');
    },
    //This is not needed, jsut in case we need it for later use
    getProduct({commit}, {shop_id, delivery_type, product_id, variant_id}) {
        commit('findProduct', {
            "shop_id": shop_id,
            "delivery_type": delivery_type,
            "product_id": product_id,
            "variant_id": variant_id
        });
    }
};

const mutations = {
    addBlockToCart: (state, {product, cart_data}) => {
        //Get Cart
        let cart_object = state.cart;
        //Check if cart is not empty
        if (cart_object.length !== 0) {
            //Cart is not empty
            //Check if shop exists in cart
            let shop_exists = false;
            for (const block of cart_object) {
                //If shop exists
                if ((block.shop_id === product.shop.id) && (block.delivery_type === cart_data.delivery_type)) {
                    shop_exists = true;
                    //Check if product exists
                    let product_variant_exists = false;
                    for (const cart_shop_product of block.products) {
                        if (cart_shop_product.product_id === product.id) {
                            //Check if product is the same variant
                            if (cart_shop_product.variant === cart_data.variant) {
                                //Product with same variant exists
                                product_variant_exists = true;
                                //Modify the quantity
                                cart_shop_product.quantity += cart_data.quantity;
                                //Merge product extras
                                if (cart_data.product_extras.length !== 0) {
                                    for (const key in cart_data.product_extras) {
                                        //Check if product extra key exists in the cart
                                        if (cart_shop_product.product_extras[key] !== undefined) {
                                            //Append new quanity
                                            cart_shop_product.product_extras[key] +=
                                                cart_data.product_extras[key];
                                        } else {
                                            cart_shop_product.product_extras[key] =
                                                cart_data.product_extras[key];
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //Product Variant doesn't exit
                    if (product_variant_exists === false) {
                        block.products.push(cart_data);
                    }
                }
            }

            //If shop doesn't exist
            if (shop_exists === false) {
                //Push the shop + product
                cart_object.push({
                    id: parseInt(momentTimezone().format("x") + Math.floor(Math.random() * 100)),
                    shop_id: product.shop.id,
                    delivery_type: cart_data.delivery_type,
                    products: [cart_data],
                    prescription_files: [],
                    payment_method: "bank",
                });
            }
        } else {
            //Cart is empty
            cart_object.push({
                id: parseInt(momentTimezone().format("x") + Math.floor(Math.random() * 100)),
                shop_id: product.shop.id,
                delivery_type: cart_data.delivery_type,
                products: [cart_data],
                prescription_files: [],
                payment_method: "bank",
            });
        }

        //Save to storage
        localStorage.setItem("cart", JSON.stringify(cart_object));
    },
    removeGroup: (state, group_id) => {
        state.cart = state.cart.filter(group => {
            return group.id !== group_id;
        });

        //Update Storage
        let cart_object = state.cart;
        localStorage.setItem("cart", JSON.stringify(cart_object));
    },
    updateGroupPaymentMethod: (state, {group_id, payment_method}) => {
        state.cart.map(group => {
            if (group.id === group_id) {
                group.payment_method = payment_method;
            }
        });
    },
    updateGroupAddressId: (state, {group_id, address_id}) => {
        state.cart.map(group => {
            if (group.id === group_id) {
                group.address_id = address_id;
            }
        });
    },
    removeProduct: (state, {group_id, product_id}) => {
        state.cart.map(cart_group => {
            if(cart_group.id === group_id){
                console.log("Found it: " + group_id);
                console.log("product_id: " + product_id);

                //Filter group products
                cart_group.products = cart_group.products.filter(product => {
                   if(product.product_id !== product_id){
                       return true;
                   }
                });

                //If cart group products is empty delete the group
                if(Object.keys(cart_group.products).length === 0){
                    state.cart = state.cart.filter(group => {
                        return group.id !== group_id;
                    });
                }
            }
        });
    },
    emptyCart: (state) => {
        state.cart = [];
        localStorage.setItem("cart", JSON.stringify(state.cart));
    },
    findProduct: (state, {shop_id, delivery_type, product_id, variant_id}) => {
        //Get Cart
        let cart_object = state.cart;
        //Check if cart is not empty
        if (cart_object.length !== 0) {
            //Cart is not empty
            //Check if shop exists in cart
            for (const block of cart_object) {
                //If shop exists
                if ((block.shop_id === shop_id) && (block.delivery_type === delivery_type)) {
                    //Check if product exists
                    for (const cart_shop_product of block.products) {
                        if (cart_shop_product.product_id === product_id) {
                            //Check if product is the same variant
                            if (variant_id !== null) {
                                if (cart_shop_product.variant === variant_id) {
                                    return cart_shop_product;
                                }
                            } else {
                                return cart_shop_product;
                            }
                        }
                    }
                }
            }
        }

        return null;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
