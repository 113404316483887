import { defineRule } from "vee-validate";
import { required, email, confirmed, min } from "@vee-validate/rules";
//Read this documentation on how to use
//https://vee-validate.logaretm.com/v4/guide/global-validators\

// 'alpha_dash'
// 'alpha_num'
// 'alpha_spaces'
// 'alpha'
// 'between'
// 'confirmed'
// 'digits'
// 'dimensions'
// 'email'
// 'ext'
// 'image'
// 'integer'
// 'is_not'
// 'is'
// 'length'
// 'max_value'
// 'max'
// 'mimes'
// 'min_value'
// 'min'
// 'not_one_of'
// 'numeric'
// 'one_of'
// 'regex'
// 'required'
// 'size'

defineRule("required", required);
defineRule("email", email);
defineRule("confirmed", confirmed);
defineRule("min", min);
