import {Promise} from "core-js";

const state = {
    currency: localStorage.getItem("currency") ?? null,
    currencies: [],
};

const getters = {
    currencies: (state) => state.currencies,
    currency: (state) => state.currency,
};

const actions = {
    async getCurrencies({commit}) {
        return new Promise((resolve, reject) => {
            window.axios
                .get(window.Routes.getRoute("currencies_active"))
                .then(
                    (response) => {
                        this.currencies = response.data.data;
                        commit("setCurrencies", this.currencies);
                        resolve(this.currencies);
                    },
                    (error) => {
                        commit("setErrors", error.response.data.errors);
                        reject(error.response.data.errors);
                    }
                );
        });
    },
    async setCurrency({commit}, currency) {
        return new Promise(resolve => {
            commit("setCurrency", currency);
            resolve(currency);
        });
    }
};

const mutations = {
    setCurrencies: (state, data) => {
        state.currencies = data;
    },
    setCurrency: (state, currency) => {
        if(currency === "none") {
            currency = null;
        }
        localStorage.setItem("currency", currency);
        state.currency = currency;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
