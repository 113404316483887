import router from "@/router";
import {language} from "@/includes/i18n";

const state = {
  user: {},
  access_token: null
};

const getters = {
  user: state => state.user,
  access_token: state => state.access_token
};

const actions = {
  async checkGuestAddress({dispatch}) {
    return new Promise((resolve, reject) => {
      let guestAddress = localStorage.getItem('guestAddress') ? JSON.parse(localStorage.getItem('guestAddress')) : null;
      if(guestAddress){
        // Check if primary key is set
        if(!guestAddress.primary) {
          guestAddress.primary = true;
        }

        //Save as primary address
        dispatch("createAddress", guestAddress).then((response) => {
          resolve(response);
        }, (errors) => {
          reject(errors);
        }).finally(() => {
          //Remove address from storage
          localStorage.removeItem("guestAddress");
        });
      } else {
        reject({
          "errors" : {
            "code" : "guest_address.missing",
            "message" : "Guest address is not present"
          }
        });
      }
    });
  },
  async getUserAddresses({dispatch}) {
    await dispatch("getAddresses");
  },

  async changeLanguage({dispatch}, language) {
    await dispatch("setLanguage", language);
  },

  async authRegister({commit, dispatch}, formData) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(window.Routes.getRoute("register"), {
          name: formData["name"],
          email: formData["email"],
          password: formData["password"],
          password_confirmation: formData["password_confirmation"]
        })
        .then(
          response => {
            let data = response.data.success;

            commit("setUser", data.user);
            commit("setAccessToken", data.access_token);

            //If language if different, change the language
            if (language !== data.user.locale) {
              //Change Language
              dispatch("changeLanguage", language);
            }

            resolve(data);

            dispatch("getUserAddresses").then(() => {
              // Redirect to previous page
              router.go(router.currentRoute);
            });
          },
          error => {
            commit("setErrors", [error.response.data.errors, "register"]);
            reject(error.response.data.errors);
          }
        );
    });
  },
  async authLogin({commit, dispatch}, credentials) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(window.Routes.getRoute("login"), {
          email: credentials["email"],
          password: credentials["password"],
          application_type: "customer"
        })
        .then(
          response => {
            let data = response.data.success;
            commit("setUser", data.user);
            commit("setAccessToken", data.access_token);

            //If language if different, change the language
            if (language !== data.user.locale) {
              //Change Language
              dispatch("changeLanguage", language);
            }

            resolve(data);

            //Check if guest address is present
            dispatch("checkGuestAddress").finally(() => {
              dispatch("getUserAddresses").then(() => {
                // Redirect to previous page
                router.go(router.currentRoute);
              });
            });
          },
          error => {
            commit("setErrors", [error.response.data.errors, "login"]);
            reject(error.response.data.errors);
          }
        );
    });
  },
  async authSocialLogin({commit, dispatch}, credentials) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(window.Routes.getRoute("social_login"), {
          provider: credentials["provider"],
          access_token: credentials["access_token"],
          application_type: "customer"
        })
        .then(
          response => {
            let data = response.data.success;
            commit("setUser", data.user);
            commit("setAccessToken", data.access_token);

            //If language if different, change the language
            if (language !== data.user.locale) {
              //Change Language
              dispatch("changeLanguage", language);
            }

            resolve(data);

            //Check if guest address is present
            dispatch("checkGuestAddress").finally(() => {
              dispatch("getUserAddresses").then(() => {
                // Redirect to previous page
                router.go(router.currentRoute);
              });
            });
          },
          error => {
            commit("setErrors", [error.response.data.errors, "login"]);
            reject(error.response.data.errors);
          }
        );
    });
  },
  async authLogout({commit}) {
    return new Promise((resolve, reject) => {
      let firebase_token = localStorage.getItem("firebase_token");
      commit("unsetUser", []);
      // Redirect
      // A redirect is needed because we will be cleaning all localstorage and the state will be refreshed
      document.location.href = "/";
      resolve("success");
      window.axios
        .post(window.Routes.getRoute("logout"), {
          firebase_token: firebase_token ? firebase_token : undefined
        })
        .catch(error => {
          commit("setErrors", [error.response.data.errors, "logout"]);
          reject(error.response.data.errors);
        });
    });

  },
  checkAccessToken({commit}) {
    if (localStorage.access_token !== undefined) {
      commit("setUser", JSON.parse(localStorage.getItem("user")));
      commit("setAccessToken", localStorage.getItem("access_token"));
    }
  }
};

const mutations = {
  setAccessToken(state, access_token) {
    state.access_token = access_token;

    if (localStorage.access_token === undefined) {
      localStorage.setItem("access_token", access_token);
    }

    window.axios.defaults.headers.common = {
      Authorization: `Bearer ${localStorage.access_token}`
    };
  },
  setUser: (state, user) => {
    state.user = user;

    localStorage.setItem("user", JSON.stringify(user));
  },

  unsetUser: state => {
    state.user = [];
    state.access_token = null;

    //Clear Local Storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("primaryAddress");
    localStorage.removeItem("firebase_token");
    localStorage.removeItem("cart");
    localStorage.removeItem("phone");
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
