<template>
  <div class="NavButtonLanguages">
    <nav-button
        @click.stop=""
        v-bind:menuName="menuName"
        @navButton-clicked="navButtonClicked"
        v-bind:active="activeMenu === menuName"
    >
      <template v-slot:button>
        <div class="Aligner items-left">
          <img
              class="current_lang"
              :src="require('@/assets/lang/' + $i18n.locale + '.png')"
              alt=""
          />
          <span class="ps-2 color-secondary" v-if="currency !== 'auto'">/ {{ currency }}</span>
        </div>
      </template>
      <template v-slot:dropdown>
        <nav-button-languages-dropdown style="min-width: 250px"/>
      </template>
    </nav-button>
  </div>
</template>

<script>
import NavButton from "./NavButton.vue";
import NavButtonLanguagesDropdown from "./NavButtonLanguagesDropdown.vue";
import {mapGetters} from "vuex";

export default {
  name: "NavButtonLanguages",
  components: {NavButtonLanguagesDropdown, NavButton},
  props: ["activeMenu", "menuName"],
  methods: {
    navButtonClicked() {
      this.$emit("navButton-clicked", this.menuName);
    },
  },
  computed: {
    ...mapGetters(['currency'])
  }
};
</script>

<style lang='scss'>
.NavButtonLanguages {
  .navButton {
    .button {
      .current_lang {
        height: 20px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .NavButtonLanguages {
    .navButton {
      .button {
        padding-left: 0;
      }
    }
  }
}
</style>


