<template>
  <div class="errors-holder" :class="{'mb-3' : errorsCount !== 0}">
    <div
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
      v-for="error in errorsMerged"
      v-bind:key="error.code !== undefined ? error.code : error"
    >
      {{ error.message !== undefined ? error.message : error }}
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Errors",
  props: {
    type: {
      type: String,
      default: function () {
        return "system";
      },
    },
  },
  computed: {
    ...mapGetters(["errors", "validation_errors"]),
    errorsMerged: function () {
      if (this.type === "system") {
        return this.errors;
      } else if (this.type === "validation_errors") {
        return this.validation_errors;
      } else {
        //Get all
        return {
          ...this.errors,
          ...this.validation_errors,
        };
      }
    },
    errorsCount: function(){
      return Object.keys(this.errorsMerged).length;
    }
  },
};
</script>

<style lang="scss">

</style>
