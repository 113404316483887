// import { Promise } from "core-js";

const state = {
  counter: 0,
  package: {
    products: [],
  },
  the_package: null,
  packages: [],
  package_product: {
    extras: [],
    variants: [],
  },
  day: {
    dayIndex: 0,
    dayValue: 1,
  },
  week: {
    dayIndex: 0,
    dayValue: 1,
  },
  packageShow: null,
  packageShowExtraInfo: null,
  packageDelete: null,
  packageUpdate: null,
  packageUpdateReplicate: {
    packageNameUpdate: null,
    packageRecurring: null,
    packageCurrency: null,
    packageAddress: null,
  },
};
const mutations = {
  setPackage(state, payload) {
    state.package = payload;
    state.packages.push(payload);
  },
  add_to_package(state, payload) {
    state.packages = [...state.packages, ...payload];
    state.packages.forEach((element) => {
      state.counter = 0;
      state.packages.forEach((checkElement, index) => {
        if (element.id == checkElement.id) {
          state.counter++;
          if (state.counter >= 2) {
            state.packages.splice(index, 1);
          }
        }
      });
    });
  },
  reset_packages(state) {
    state.packages = [];
  },
  package_product(state, payload) {
    let counter = 0;
    for (let extra in payload.product_extras) {
      state.package_product.extras[counter] = {
        id: extra,
        quantity: payload.product_extras[extra],
      };
      counter++;
    }
    state.package_product.product_id = payload.product_id;
    state.package_product.quantity = payload.quantity;
    state.package_product.variants.push(payload.variant);
  },
  set_product(state, payload) {
    for (let single_package in state.packages) {
      if (payload.package_id == state.packages[single_package].id) {
        state.the_package = single_package;
        break;
      }
    }
    if (
      state.packages[state.the_package].products == null ||
      state.packages[state.the_package].products.length == 0
    ) {
      state.packages[state.the_package].products = [];
      state.packages[state.the_package].products.push(payload);
    } else {
      state.packages[state.the_package].products.push(payload);
    }
  },
  set_day(state, payload) {
    if (payload != null) {
      if (Object.keys(payload.week).length > 0) {
        state.week.dayIndex = payload.week.dayIndex;
        state.week.dayValue = payload.week.dayValue;
      } else {
        state.day.dayIndex = payload.day.dayIndex;
        state.day.dayValue = payload.day.dayValue;
      }
    }
    if (payload === null) {
      state.day.dayIndex = 0;
      state.day.dayValue = 1;
      state.week.dayIndex = 0;
      state.week.dayValue = 1;
    }
  },
  package_show(state, payload) {
    state.packageShow = payload;
  },
  package_show_extra_info(state, payload) {
    state.packageShowExtraInfo = payload;
  },
  package_delete(state, payload) {
    state.packageDelete = payload;
  },
  package_update(state, payload) {
    state.packageUpdate = payload;
  },
  replicate_for_update(state, payload) {
    state.packageUpdateReplicate.packageNameUpdate = payload;
    state.packageUpdateReplicate.packageRecurring = payload;
    state.packageUpdateReplicate.packageCurrency = payload;
    state.packageUpdateReplicate.packageAddress = payload;
  },
  reset_package_val(state, payload) {
    state.packageShow = payload;
  },
};
const actions = {
  async setPackage(state, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(window.Routes.getRoute("packages_index"), {
          name: payload.name,
          shop_id: payload.shop_id,
          preferred_currency: payload.preferred_currency,
        })
        .then(
          (response) => {
            state.commit("setPackage", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            state.commit("setErrors", [error.response.data.errors]);
            reject(error.response.data.errors);
          }
        );
    });
  },
  async setProduct(state, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(
          window.Routes.getRoute("save_package_product", {
            package_id: payload.package_id,
            product_id: payload.product_id,
          }),
          payload
        )
        .then(
          (response) => {
            state.commit("set_product", payload);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data.errors);
          }
        );
    });
  },
  async packageUpdate(state, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(
          window.Routes.getRoute("packages_update", {
            package_id: payload.id,
          }),
          payload
        )
        .then(
          (response) => {
            state.commit("package_update", "success");
            resolve(response.data.data);
          },
          (error) => {
            state.commit("package_update", "error");
            reject(error.response.data.errors);
          }
        );
    });
  },
  add_to_package(state, payload) {
    state.commit("add_to_package", payload);
  },
  add_package_product(state, payload) {
    state.commit("package_product", payload);
  },
  setDay(state, payload) {
    state.commit("set_day", payload);
  },
  destroyDay(state) {
    state.commit("set_day", null);
  },
  async getPackage(state, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(
          window.Routes.getRoute("packages_show", {
            package_id: payload,
          })
        )
        .then(
          (response) => {
            state.commit("package_show", response.data.data);
            state.commit("replicate_for_update", response.data.data);
            state.commit("package_show_extra_info", response.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.response.data.errors);
          }
        );
    });
  },
  async deletePack(state, payload) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(
          window.Routes.getRoute("packages_update", {
            package_id: payload,
          })
        )
        .then(
          (response) => {
            state.commit("package_delete", "success");
            // console.log()
            resolve(response.data.data);
          },
          (error) => {
            state.commit("package_delete", "error");

            reject(error.response.data.errors);
          }
        );
    });
  },
  resetPackageVal(state) {
    state.commit("reset_package_val", null);
  },
  resetPackages(state) {
    state.commit("reset_packages");
  },
};
const getters = {
  getPackages: (state) => state.packages,
  getPackage: (state) => state.package,
  getPackageProduct: (state) => state.package_product,
  getDay: (state) => state.day,
  getWeek: (state) => state.week,
  packageShow: (state) => state.packageShow,
  packageShowExtraInfo: (state) => state.packageShowExtraInfo,
  packageDelete: (state) => state.packageDelete,
  packageUpdate: (state) => state.packageUpdate,
  packageUpdateReplicate: (state) => state.packageUpdateReplicate,
};
//Getter => computed properties State => the data , action => methods
// Action call mutations which update the state => Vuex flow
export default {
  state,
  getters,
  mutations,
  actions,
};
