<template>
  <div class="NavButtonCategories">
    <nav-button
      @click.stop=""
      v-bind:menuName="menuName"
      @navButton-clicked="navButtonClicked"
      v-bind:active="activeMenu === menuName"
      arrowless="true"
    >
      <template v-slot:button>
        <span class="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </template>
      <template v-slot:dropdown>
        <div class="dropdown">
          <div class="title font-weight-bold pb-2 font-size-big">
            {{ $t("global.all") }} {{ $t("categories.title") }}
          </div>
          <perfect-scrollbar :options="{wheelPropagation: false}">
            <p v-if="categories_count === 0">{{ $t("global.loading") }}</p>
            <ul>
              <li
                v-for="category in categories"
                v-bind:key="category.id"
                class="hasArrow hasLink"
              >
                <router-link class='category-link' v-bind:to="'/categories/' + category.id + '?title=' + category.name">
                  <img
                    class="icon"
                    v-if="category.logo != null"
                    v-bind:src="window.Routes.getImage(category.logo)"
                  />
                  {{ category.name }}
                </router-link>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
      </template>
    </nav-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavButton from "./NavButton.vue";
export default {
  name: "NavButtonCategories",
  components: { NavButton },
  props: ["activeMenu", "menuName"],
  computed: {
    ...mapGetters(["categories"]),
    categories_count: function () {
      return Object.keys(this.categories).length;
    },
  },
  methods: {
    ...mapActions(["getCategories"]),
    navButtonClicked() {
      //Check if categories state is empty
      if (this.categories_count === 0) {
        this.getCategories();
      }

      this.$emit("navButton-clicked", this.menuName);
    },
  },
};
</script>

<style lang='scss'>
.NavButtonCategories {
  ul {
    li {
      .category-link {
        .icon {
          padding: 0;

          background-color: map-get($colors, "gray");
          border-radius: 3px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .NavButtonCategories {
    .navButton {
      margin-right: map-get($spacings, "default");
      position: static;
      .button {
        padding-left: 0;
        position: static;
      }

      .dropdown {
        position: absolute;
        top: map-get($defaults, "header-height-mobile");
        left: 0;
        width: 100%;
        max-width: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .NavButtonCategories {
    .navButton {
      margin-right: 0;
      position: relative;
      .button {
        position: relative;
      }
      &.active {
        .button {
          z-index: 9999;
          background-color: transparent;
          &:after {
            display: none;
          }

          .hamburger {
          }
        }
      }

      .dropdown {
        position: fixed;
        padding-top: map-get($defaults, "header-height-mobile");
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: none;

        .ps {
          max-height: 100%;
        }
      }
    }
  }
}
</style>
