<template>
  <div class="languagesDropdown">
    <div class="dropdown" v-bind:style="style">

      <div class="w-100 mb-3">
        <label class="text-uppercase color-secondary mb-1">{{ $t("languages.language") }}</label>
        <nav-button-languages-select/>
      </div>

      <div class="w-100">
        <label class="text-uppercase color-secondary mb-1">{{ $t("currencies.currency") }}</label>
        <nav-button-currency-select/>
      </div>

    </div>
  </div>
</template>

<script>


import NavButtonLanguagesSelect from "@/views/Layout/partials/Nav/NavButtonLanguagesSelect";
import NavButtonCurrencySelect from "@/views/Layout/partials/Nav/NavButtonCurrencySelect";

export default {
  name: "NavButtonLanguagesDropdown",
  components: {NavButtonCurrencySelect, NavButtonLanguagesSelect},
  props: ["style"],
};

</script>

<style lang='scss'>
.navButton {
  .languagesDropdown {
    .dropdown {
      ul {
        li {
          .form-check-label {
            margin-top: 0px;

            .image {
              img {
                height: 30px;
                border-radius: 2px;
                overflow: hidden;
              }
            }

            .title {
              padding-left: map-get($spacings, "small");
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navButton {
    .languagesDropdown {
      .dropdown {
        left: auto;
        right: 0;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .navButton {
    .languagesDropdown {
      .dropdown {
        position: fixed;
        top: map-get($defaults, "header-height-mobile");
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: none;

        .ps {
          max-height: 100%;
        }
      }
    }
  }
}
</style>
