import momentTimezone from "moment-timezone";

const state = {
    notifications: [],
};

const getters = {
    notifications: (state) => state.notifications,
};

const actions = {
    createNotification({commit}, payload) {
        console.log(payload);
        let data = payload.data;
        let notification = payload.notification;

        let notificationObject = {
            //Generate a random id
            'id': momentTimezone().format("x") + Math.floor(Math.random() * 100),
            'title': notification.title,
            'body': notification.body,
            'image': null,
            'importance': data.importance,
            'model': data.model,
            'model_id': data.id,
            'created_at': momentTimezone().format()
        };

        commit("addNotification", notificationObject);
    },
    deleteNotification({commit}, notification_id) {
        commit("deleteNotification", notification_id);
    },
    enableNotificationCleaner({commit}) {
        commit("cleaner");
    }
};

const mutations = {
    addNotification: (state, notification) => {
        state.notifications.push(notification);
    },
    deleteNotification: (state, notification_id) => {
        state.notifications = state.notifications.filter(
            (notification) => notification.id !== notification_id
        );
    },
    // eslint-disable-next-line no-unused-vars
    cleaner: (state) => {
        setInterval(() => {
            //Got through each notification and clear notifications older than 10 seconds
            state.notifications = state.notifications.filter(notification => {
                return momentTimezone().subtract(30, 's').isBefore(notification.created_at);
            })
        }, 1000);
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
