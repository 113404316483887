import {Promise} from "core-js";

const state = {};

const getters = {};

const actions = {
  async getProfile({commit}) {
    return new Promise((resolve, reject) => {
      let url = window.Routes.getRoute("get_profile");
      window.axios.get(url).then(
        response => {
          resolve(response.data.data);
          commit('setUser', response.data.data, {root: true});
        },
        error => {
          reject(error.response.data.errors);
          commit("setErrors", error.response.data.errors);
        }
      );
    });
  },
  async editProfile({commit}, {email, name, phone}) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(window.Routes.getRoute("update_profile"), {
          email: email,
          name: name,
          phone: phone
        })
        .then(
          ({data}) => {
            commit('setUser', data.data, { root: true });
            resolve(data);
          },
          error => {
            commit("setErrors", [error.response.data.errors]);
            reject(error.response.data.errors);
          }
        );
    });
  },
  async changePassword(
    {commit},
    {oldPassword, newPassword, newPasswordConfirmation}
  ) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(window.Routes.getRoute("change_password"), {
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordConfirmation
        })
        .then(
          response => {
            resolve(response.data.data);
          },
          error => {
            commit("setErrors", [error.response.data.errors, "password"]);
            reject(error.response.data.errors);
          }
        );
    });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
