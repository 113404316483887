<template>
  <div class="overlayWrapper" v-bind:class="{ active: active }">
    <div
      class="overlay"
      @click.prevent.stop="overlayClicked"
      v-bind:style="style"
      v-bind:class="{ active: active }"
    ></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Overlay",
  props: ["active", "style"],
  methods: {
    overlayClicked() {
      this.$emit("overlayClicked", true);
    },
  },
};
</script>

<style lang='scss'>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  z-index: 9997;

  &.active {
    display: block;
  }

  & + div {
    position: relative;
    z-index: 9998;
  }
}
</style>
