import store from "./../store";
import { createRouter, createWebHashHistory } from "vue-router";

// Middlewares
import MiddlewarePipeline from "../middlewares/MiddlewarePipeline";
import AuthMiddleware from "../middlewares/AuthMiddleware";
import GuestMiddleware from "../middlewares/GuestMiddleware";
import LocationMiddleware from "../middlewares/LocationMiddleware";
import PhoneNumberMiddleware from "../middlewares/PhoneNumberMiddleware";
import GuestAddressMiddleware from "../middlewares/GuestAddressMiddleware";

const routes = [
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("../views/Welcome/Index.vue"),
    meta: {
      middleware: [GuestMiddleware],
      showNav: false
    }
  },
  {
    path: "/guest-address/create",
    name: "GuestAddressCreate",
    component: () => import("../views/GuestAddress/Create.vue"),
    meta: {
      middleware: [GuestMiddleware],
      showNav: false
    }
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home/HomeShow.vue"),
    meta: {
      middleware: [GuestAddressMiddleware, PhoneNumberMiddleware, LocationMiddleware]
    }
  },
  {
    path: "/near-you",
    name: "NearYou",
    component: () => import("../views/NearYou/Index.vue"),
    meta: {
      middleware: [GuestAddressMiddleware, LocationMiddleware]
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
    meta: {
      middleware: [GuestMiddleware],
      showNav: false,
      showFooter: false,
      showErrors: false
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/Auth/ForgotPassword.vue"),
    meta: {
      middleware: [GuestMiddleware]
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Auth/Register.vue"),
    meta: {
      middleware: [GuestMiddleware],
      showNav: false,
      showFooter: false,
      showErrors: false
    }
  },
  {
    path: "/addresses",
    name: "Addresses",
    component: () => import("../views/Addresses/Index.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/addresses/:id/edit",
    name: "AddressesEdit",
    component: () => import("../views/Addresses/Edit.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/addresses/create",
    name: "AddressesCreate",
    component: () => import("../views/Addresses/Create.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/Cart/CartShow.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/checkout/:id",
    name: "CheckoutShow",
    component: () => import("../views/Checkout/CheckoutShow.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/checkout/success",
    name: "CheckoutSuccess",
    component: () => import("../views/Checkout/CheckoutSuccess.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/categories/:id",
    name: "CategoriesPackedProducts",
    component: () => import("../views/Categories/CategoriesShow.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/categories/:type/:id",
    name: "CategoriesProducts",
    component: () => import("../views/Categories/CategoriesShow.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/packages",
    name: "PackagesIndex",
    component: () => import("../views/Packages/Index.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/packages/:id",
    name: "PackagesShow",
    component: () => import("../views/Packages/Show.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/orders",
    name: "OrdersIndex",
    component: () => import("../views/Orders/OrdersIndex.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/orders/:id",
    name: "OrdersShow",
    component: () => import("../views/Orders/OrdersShow.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/orders/:id/pay",
    name: "OrderPay",
    component: () => import("../views/OrderPay/PayShow"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/products/:id",
    name: "ProductShow",
    component: () => import("../views/Products/ProductsShow.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/shops/:id",
    name: "ShopShow",
    component: () => import("../views/Shops/ShopShow.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/shops/:id/featuredProducts",
    name: "FeaturedProducts",
    component: () => import("../views/Shops/ShopProducts.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/shops/:id/allProducts",
    name: "AllProducts",
    component: () => import("../views/Shops/ShopProducts.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/search/:query",
    name: "Search",
    component: () => import("../views/Search/Search.vue"),
    meta: {
      middleware: [GuestAddressMiddleware]
    }
  },
  {
    path: "/profile",
    name: "PersonalInformation",
    component: () =>
      import("../views/PersonalInformation/PersonalInformation.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/verifyPhone/verifyNewNumber/:number",
    name: "VerifyPhone",
    component: () => import("../views/VerifyPhone/VerifyPhone.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/verifyPhone/verifyNumber",
    name: "NumberPhone",
    component: () => import("../views/VerifyPhone/NumberPhone.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  {
    path: "/dispute/:id",
    name: "DisputeShow",
    component: () => import("../views/Dispute/DisputeShow.vue"),
    meta: {
      middleware: [AuthMiddleware]
    }
  },
  // GLOBAL
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404/Show.vue")
  },
  {
    path: "/:PageNotFound(.*)*",
    name: "PageNotFound",
    component: () => import("../views/404/Show.vue")
  },
  // PAGES
  {
    path: "/pages/en/privacy-policy",
    name: "PrivacyPolicy-En",
    component: () => import("../views/Pages/PrivacyPolicy/PrivacyPolicyEn.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store
  };

  // Clear errors on route change
  store.dispatch("clearErrors");

  return middleware[0]({
    ...context,
    next: MiddlewarePipeline(context, middleware, 1)
  });
});

export default router;
