import { Promise } from "core-js";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";

const state = {
	homepageProductSections: [],
};

const getters = {
	homepageProductSections: (state) => state.homepageProductSections,
};

const actions = {
	async getHomepageProductSections({ commit }, form_data = {}) {
		commit("setHomepageProductSections", []);
		if (form_data["pagination"] === undefined) {
			form_data["pagination"] = 6;
		}

		return new Promise((resolve, reject) => {
			let url = window.Routes.getRoute("homepageProductSections");
			window.axios.post(url, prepareGuestAddressParams(form_data)).then(
				(response) => {
					commit("setHomepageProductSections", response.data);
					resolve(response.data);
				},
				(error) => {
					commit("setErrors", error.response.data.errors);
					reject(error.response.data.errors);
				}
			);
		});
	},
};

const mutations = {
	setHomepageProductSections: (state, data) => {
		state.homepageProductSections = data.data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
