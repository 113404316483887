<template>
  <select2
      v-model="selected_currency"
      class="select2-rounded mb-0"
      :options="currenciesForSelect2"
      :settings="{
        templateResult:select2template,
        templateSelection:select2template,
        dropdownCssClass: 'select2-rounded',
        placeholder: $t('currencies.shop_based'),
      }"
      @select="currencyChanged($event)"
  />
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Select2 from "vue3-select2-component";

export default {
  name: "NavButtonCurrencySelect",
  components: {
    Select2
  },
  data() {
    return {
      selected_currency: null,
    }
  },

  watch: {
    language: {
      immediate: true,
      handler() {
        this.selected_currency = this.currency;
      }
    }
  },
  computed: {
    ...mapGetters(["currencies", "currency"]),
    currenciesForSelect2() {
      let computed_array = [];

      //Add Shop based currency
      computed_array.push({
        id: "auto",
        text: this.$t('currencies.shop_based'),
        image: null,
        selected: this.selected_currency === null
      });

      for (const currency of this.currencies) {
        computed_array.push({
          id: currency.code,
          text: currency.code,
          image: currency.flag_png,
          selected: currency.code === this.selected_currency
        });
      }

      return computed_array;
    }
  },
  methods: {
    ...mapActions(["getCurrencies", "setCurrency"]),
    currencyChanged(event) {
      let currency = event.id;
      this.setCurrency(currency).then(() => {
        location.reload();
      });
    },
    select2template(state) {
      // return state.text;
      if (!state.id) {
        return state.text;
      }

      //Generate Dom Template
      let dom = document.createElement('div');
      dom.classList.add("flag");
      if (state.image != null) {
        dom.innerHTML = '<img src="' + state.image + '" class="img-flag" /> ' + state.text + '';
      } else {
        dom.innerText = state.text;
      }
      return dom;
    }
  },
  created() {
    this.getCurrencies();
  }
}
</script>

<style lang="scss">
.select2-rounded {
  .select2-container {
    width: 100% !important;
  }

  .flag {
    display: flex;
    align-items: center;

    img {
      width: 25px;
      margin-right: 7px;
    }
  }
}
</style>
