export default function formatPrice(currency, amount, round = 2) {
	amount = parseFloat(amount)
		.toFixed(round)
		.replace(/\d(?=(\d{3})+\.)/g, '$&,');

	switch (currency) {
		case "EUR":
			return amount + "€";
		case "USD":
			return "$" + amount;
		case "EGP":
			return "£" + amount;
		case "CAD":
			return "CA $" + amount;
		default:
			return currency + " " + amount;
	}
}
