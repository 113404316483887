export default function LocationMiddleware({ next }) {
  //If user is logged in
  if (localStorage.getItem("user")) {
    //Check if primary address has been set
    if (localStorage.getItem("primaryAddress") == undefined) {
      return next({
        name: "AddressesCreate"
      });
    }
  }


  return next();
}
