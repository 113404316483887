import { Promise } from "core-js";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";

const state = {
  shop: {}
};

const getters = {
  shop: state => state.shop
};

const actions = {
  async getShopProfile({ commit }, { shopId }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(
          window.Routes.getRoute("shop_view", {
            shop_id: shopId
          }),{
            params: prepareGuestAddressParams()
          }
        )
        .then(
          response => {
            resolve(response.data.data);
            commit("setShop", response.data);
          },
          error => {
            commit("setErrors", [error.response.data.errors]);
            reject(error.response.data.errors);
          }
        );
    });
  }
};

const mutations = {
  setShop: (state, data) => {
    state.shop = data.data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
