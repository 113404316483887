<template>
  <div id="NavCart">
    <div>
      <router-link to="/cart">
        <div class="icon">
          <div
            class="counter font-size-big"
            v-bind:class="{ empty: cartProductsCounter === 0, plus: cartProductsCounter > 9 }"
          >
            {{ cart_count_computed }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "NavCart",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["cartProductsCounter"]),
    cart_count_computed: function () {
      if (this.cartProductsCounter <= 9) {
        return this.cartProductsCounter;
      } else {
        return 9;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$NavCartCounter_size: 20px;


#NavCart {
  margin-left: map-get($spacings, "medium");
  margin-right: map-get($spacings, "medium");
  padding-left: map-get($spacings, "medium");
  padding-right: map-get($spacings, "medium");
  border-left: 1px solid map-get($defaults, "border-color");
  border-right: 1px solid map-get($defaults, "border-color");

  a {
    display: block;
    width: auto;
    float: left;

    .icon {
      width: 25px;
      height: 25px;
      float: left;
      background-image: url(~@/assets/nav/cart.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      position: relative;

      .counter {
        position: absolute;
        width: $NavCartCounter_size;
        height: $NavCartCounter_size;
        line-height: $NavCartCounter_size;
        text-align: center;
        text-indent: -1px;
        color: map-get($colors, "twiks");
        top: -8px;
        right: -1px;

        &.plus {
          &:after {
            font-size: get_font_size($font_sizes, "default");
            font-weight: bold;
            display: block;
            content: "+";
            width: 10px;
            height: 10px;
            text-align: center;
            line-height: 10px;
            position: absolute;
            top: -0px;
            right: -5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  #NavCart {
    margin-left: 0;
    //margin-right: 0;
  }
}

@include media-breakpoint-down(md) {
  #NavCart {
    padding-left: map-get($spacings, "default");
    padding-right: map-get($spacings, "default");
    border-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  #NavCart {
    a {
      position:relative;
      top:3px;
      .icon {
        width: 20px;
        height: 20px;

        .counter {
          top: -10px;
          right: -3px;

          &.plus {
            &:after {
              top: 1px;
              right: -4px;
            }
          }
        }
      }
    }
  }
}
</style>
