<template>
  <div id="NavGuestAddress">
    <div class="row g-0 justify-content-between">
      <div class="column col-auto col-md-12">
        <div class="font-size-sm color-twiks">
          <span class="text-uppercase">{{ guestAddress.country.slice(0, 2) }}</span>, {{ guestAddress.city }}
        </div>
      </div>
      <div class="column col col-md-12">
        <div class="font-size-default">
          <router-link to="/guest-address/create" class="color-black hover-color-black text-decoration-underline">
            {{ $t("addresses.change_address") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavGuestAddress",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['guestAddress'])
  }
};
</script>

<style lang='scss' scoped>
#NavGuestAddress {
  margin-right: map-get($spacings, "medium");

  & > .row {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  #NavGuestAddress {
    width: 100%;
    margin-right: 0;

    & > .row {
      display: flex;
      margin-left: - (map-get($spacings, "default"));
      margin-right: - (map-get($spacings, "default"));
      padding-left: map-get($spacings, "default");
      padding-right: map-get($spacings, "default");
      background-color: map-get($colors, "lightGray");
      border-top: 1px solid map-get($defaults, "border-color");

      .column {
        display: flex;
        align-items: center;

        &:last-of-type {
          justify-content: flex-end;
          min-height: 40px;
        }
      }

    }
  }
}
</style>
