<template>
  <div id="header" :class="{guest: access_token === null, auth: access_token !== null}">
    <div class="container" id="nav">
      <div class="row g-0">
        <div class="col-auto order-1">
          <div class="row g-0">
            <div class="col-auto Aligner logo order-2 order-md-1">
              <nav-button :arrowless="true">
                <template v-slot:button>
                  <router-link to="/">
                    <img src="@/assets/logo.png" alt=""/>
                  </router-link>
                </template>
              </nav-button>
            </div>

            <div class="col-auto Aligner order-1 order-md-2">
              <nav-button-categories
                  menuName="categories"
                  v-bind:activeMenu="activeMenu"
                  @navButton-clicked="navButtonClicked"
              />
            </div>
          </div>
          <!-- /.row -->
        </div>
        <!-- /.col-auto -->

        <div class="col-md-auto Aligner order-4 order-md-2">
          <!--      Auth    -->
          <nav-button-addresses
              v-if="access_token !== null"
              menuName="addresses"
              v-bind:activeMenu="activeMenu"
              @navButton-clicked="navButtonClicked"
          />

          <!--     Guest     -->
          <nav-guest-address v-if="access_token === null && guestAddress != null && guestAddress.ready === true"/>
        </div>

        <div class="col-md Aligner search order-3">
          <nav-search/>
        </div>

        <div class="col col-md-auto order-2 order-md-4">
          <div class="row g-0 justify-content-end">
            <div class="col-auto Aligner">
              <nav-button-languages
                  menuName="languages"
                  v-bind:activeMenu="activeMenu"
                  @navButton-clicked="navButtonClicked"
              />
            </div>

            <div class="col-auto Aligner cart">
              <nav-cart/>
            </div>

            <div class="col-auto Aligner" v-if="access_token !== null">
              <nav-button-account
                  menuName="account"
                  v-bind:activeMenu="activeMenu"
                  @navButton-clicked="navButtonClicked"
              />
            </div>

            <div class="col-auto Aligner become_member" v-if="access_token === null">
              <nav-button :arrowless="true">
                <template v-slot:button>
                  <div>
                    <router-link to="/login">
                      <div class="text d-none d-lg-block">
                        <div class="username font-weight-bold color-black">
                          {{ $t("auth.login_btn") }}/{{ $t("auth.register_btn") }}
                        </div>
                      </div>
                      <div class="profile_icon d-block d-lg-none">
                        <img src="@/assets/nav/personal_info.svg"/>
                      </div>
                    </router-link>
                  </div>
                </template>
              </nav-button>
            </div>
          </div>
        </div>


      </div><!-- /.row g-0 -->
    </div>
  </div>
  <div class="header-spacer"></div>
</template>

<script>
//Vuex
import {mapGetters} from "vuex";
//Components
import NavButtonAccount from "./NavButtonAccount.vue";
import NavButtonLanguages from "./NavButtonLanguages.vue";
import NavButtonAddresses from "./NavButtonAddresses.vue";
import NavButtonCategories from "./NavButtonCategories.vue";
import NavCart from "./NavCart.vue";
import NavSearch from "./NavSearch.vue";
import NavButton from "@/views/Layout/partials/Nav/NavButton";
import NavGuestAddress from "@/views/Layout/partials/Nav/NavGuestAddress";

export default {
  name: "Nav",
  components: {
    NavGuestAddress,
    NavButton,
    NavSearch,
    NavButtonLanguages,
    NavButtonCategories,
    NavButtonAddresses,
    NavCart,
    NavButtonAccount,
  },
  data() {
    return {
      activeMenu: null,
    };
  },
  methods: {
    navButtonClicked(selectedMenu) {
      if (this.activeMenu === selectedMenu) {
        this.activeMenu = null;
      } else {
        this.activeMenu = selectedMenu;
      }
    },
  },
  computed: {
    ...mapGetters(["access_token","guestAddress"]),
  },
  watch: {
    //Close menu on router change
    $route() {
      this.activeMenu = null;
    },
  },
};
</script>

<style lang="scss">
#header {
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;

  border-bottom: 1px solid map-get($defaults, "border-color");

  #nav {
    & > .row {
      position: relative;

      .become_member {
        .button {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .logo {
        padding-right: map-get($spacings, "medium");

        .button {
          padding-left: 0;
          padding-right: 0;

          img {
            max-height: 32px;
          }
        }
      }
    }
  }
}

.header-spacer {
  height: map-get($defaults, "header-height") + 1px;
}

@include media-breakpoint-down(lg) {
  #header {
    #nav {
      & > .row {
        .button {
          .logo {
            padding-right: map-get($spacings, "default");
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #header {
    #nav {
      & > .row {
        .logo {
          padding-right: 0;

          .button {
            img {
              max-height: 28px;
            }
          }
        }
      }
    }
  }

  .header-spacer {
    height: 150px;
  }
}

@include media-breakpoint-down(sm) {
  #header {
    #nav {
      & > .row {
        .logo {
          .button {
            img {
              max-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
