<template>
  <div class="NavSearch">
    <form @submit.prevent="formSubmited">
      <input
        type="text"
        class="form-control"
        placeholder="Search for anything..."
        v-model="search_query"
        @keyup="keyUp"
      />
      <div class="searchButton" @click="submitForm"></div>
      <!-- /.searchButton -->
    </form>
  </div>
</template>

<script>
export default {
  name: "NavSearch",
  data() {
    return {
      search_query: ""
    };
  },
  methods: {
    formSubmited() {
      this.$router.push("/search/" + this.search_query);
    },
    submitForm() {
      this.formSubmited();
    },
    keyUp() {
      clearTimeout(this.keyUpTimeout);

      this.keyUpTimeout = setTimeout(() => {
        if (this.search_query.length !== 0) {
          this.formSubmited();
        }
      }, 500);
    },
    checkIfQueryIsSet() {
      if (this.query !== undefined) {
        this.search_query = this.query;
      }
    }
  },
  computed: {
    query: function() {
      return this.$route.params.query;
    }
  },
  watch: {
    query: function() {
      this.checkIfQueryIsSet();
    }
  },
  created() {
    this.checkIfQueryIsSet();
  }
};
</script>

<style lang="scss" scoped>
.NavSearch {
  position: relative;
  padding-left: map-get($spacings, "medium");
  padding-right: map-get($spacings, "medium");
  width: 100%;
  float: left;
  input {
    padding-left: map-get($spacings, "default");
    padding-right: map-get($spacings, "default") + 30px;

    &.form-control {
      width: 100%;
      float: left;
      border: 1px solid map-get($defaults, "border-color");
      line-height: 2.5;
      border-radius: map-get($defaults, "border-radius");
    }
  }

  .searchButton {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: (map-get($spacings, "default") * 2);
    background-image: url(~@/assets/nav/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;

    &:hover {
      cursor: pointer;
    }
  }
}

@include media-breakpoint-down(lg) {
  .NavSearch {
    padding-left: 0;
    padding-right: 0;

    .searchButton {
      right: map-get($spacings, "default");
    }
  }
}

@include media-breakpoint-down(md) {
  .NavSearch {
    margin-bottom: map-get($spacings, "small");
    input {
      &.form-control {
        line-height: 1.5;
      }
    }

    .searchButton {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
