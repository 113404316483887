import {createStore} from "vuex";
import languages from "./modules/languages";
import currencies from "./modules/currencies";
import auth from "./modules/auth";
import guestAddress from "@/store/modules/guestAddress";
import categories from "./modules/categories";
import addresses from "./modules/addresses";
import geolocation from "./modules/geolocation";
import orders from "./modules/orders";
import stories from "./modules/stories";
import cart from "./modules/cart";
import homepageProductSections from "./modules/homepage-product-sections";
import notifications from "@/store/modules/notifications";
import profile from "./modules/profile";
import packages from "./modules/packages";
import shop from "@/store/modules/shop";

import {Array} from "core-js";
import router from "@/router";

export default createStore({
  state: {
    errors: {},
    validation_errors: {}
  },

  getters: {
    errors: state => state.errors,
    validation_errors: state => state.validation_errors
  },

  mutations: {
    clearErrors(state, namespace = null) {
      if (namespace !== null) {
        //Clear namespaced errors
        let counter = -1;
        let filteredErrors = {};
        Object.keys(state.errors).forEach(error => {
          let this_error = state.errors[error];
          if (this_error['namespace'] !== namespace) {
            counter++;
            filteredErrors[counter] = this_error;
          }
        });

        //Clear validation errors
        let filteredValidationErrors = {};
        Object.keys(state.validation_errors).forEach(error => {
          let this_error = state.validation_errors[error];
          if (this_error['namespace'] !== namespace) {
            filteredValidationErrors[error] = this_error;
          }
        });

        // Update state
        state.errors = filteredErrors;
        state.validation_errors = filteredValidationErrors;
      } else {
        // Clear all errors
        //Clear Validation
        state.validation_errors = {};
        state.errors = {};
      }
    },
    setErrors(state, errors) {
      let namespace = null;
      if (errors[1] !== undefined) {
        namespace = errors[1];
      }
      errors = errors[0];

      //Check if user is authenticated
      if (errors["code"] !== undefined) {
        if (errors["code"] === "auth.unauthenticated") {
          // Log the user out
          this.commit("unsetUser", []);
          //Redirect
          router.replace("/login");
        }
      }

      //Clear Validation
      state.validation_errors = {};
      state.errors = {};

      //Check if value is object, Laravel Validator returns object instead of array
      if (errors instanceof Array) {
        errors = errors.map(value => {
          if (value.code === undefined) {
            value = {
              code: value,
              message: value,
            };
          }
          //Add Namespace to error
          value['namespace'] = namespace;

          return value;
        });

        state.errors = errors;
      } else {
        let validation_errors = {};
        Object.keys(errors).forEach(error => {
          let formatted_error = {
            message: errors[error][0],
            namespace: namespace
          };

          if (namespace != null) {
            validation_errors[namespace + "-" + error] = formatted_error;
          } else {
            validation_errors[error] = formatted_error;
          }
        });

        state.validation_errors = validation_errors;
      }
    }
  },

  actions: {
    setErrors({commit}, errors) {
      commit("setErrors", errors);
    },
    clearErrors({commit}, namespace = null) {
      commit("clearErrors", namespace);
    }
  },

  modules: {
    notifications,
    languages,
    guestAddress,
    auth,
    categories,
    currencies,
    addresses,
    geolocation,
    orders,
    stories,
    cart,
    homepageProductSections,
    profile,
    packages,
    shop
  }
});
