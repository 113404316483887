import { Promise } from "core-js";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";

const state = {
	stories: [],
};

const getters = {
	stories: (state) => state.stories,
};

const actions = {
	async getStories({ commit }) {
		return new Promise((resolve, reject) => {
			let url = window.Routes.getRoute("stories");
			window.axios.get(url, {
				params: prepareGuestAddressParams()
			}).then(
				(response) => {
					resolve(response.data);
					commit("setStories", response.data);
				},
				(error) => {
					reject(error.response.data.errors);
					commit("setErrors", error.response.data.errors);
				}
			);
		});
	},
};

const mutations = {
	setStories: (state, data) => {
		//Check if story has any slides at all
		data.data = data.data.filter(function(story){
			return story.stories.length !== 0;
		});

		data.data.map(function(story) {
			story.slides = story.stories;
		});

		state.stories = data.data;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
