import firebase from 'firebase/app';
import 'firebase/messaging'
import {Promise} from "core-js";


const initialize = function (firebaseConfig) {
    //Check if Firebase Messaging is supported by the browser
    if (!firebase.messaging.isSupported()){
        return null;
    }

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    // Retrieve Firebase Messaging object.
    return firebase.messaging();
}

const checkToken = function(token){
    return new Promise((resolve) => {
        //Current Token
        let current_token = localStorage.getItem("firebase_token");
        //Check if token is null
        if(token !== null){
            //Check if token is already sent
            if(token !== current_token){
                //Sending token to the serve
                console.log("Token synchronization : Sending to server");
                sendTokenToServer(token).then(() => {
                    //Save the new token to localStorage
                    localStorage.setItem("firebase_token",token);
                    resolve(true);
                }, () => {
                    localStorage.removeItem("firebase_token");
                    resolve(false);
                });
            } else {
                //Token is already sent
                console.log("Token synchronization : Already sent");
                resolve(false);
            }
        } else {
            //Clear the localStorage
            localStorage.removeItem("firebase_token");
            resolve(false);
        }
    });
}

const sendTokenToServer = async function(token) {
    return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("firebase_token_save");
        window.axios.post(url, {
            token: token
        }).then(
            (response) => {
                resolve(response.data);
            },
            (error) => {
                reject(error.response.data.errors);
            }
        );
    });
}


export {
    initialize,
    checkToken,
    sendTokenToServer
}

