import { Promise } from "core-js";

const state = {
	categories: [],
	categories_preferred_joined: [],
	categories_preferred: [],
};

const getters = {
	categories: (state) => state.categories,
	categories_preferred_joined: (state) => state.categories_preferred_joined,
	categories_preferred: (state) => state.categories_preferred,
};

const actions = {
	async getCategories({ commit }) {
		return new Promise((resolve, reject) => {
			let url = window.Routes.getRoute("categories_all");
			window.axios.get(url).then(
				(response) => {
					resolve(response.data);
					commit("setCategories", response.data);
				},
				(error) => {
					reject(error.response.data.errors);
					commit("setErrors", error.response.data.errors);
				}
			);
		});
	},

	async getCategoriesPreferredJoined({ commit }) {
		let url = window.Routes.getRoute("categories_preferred_joined");
		await window.axios.get(url).then(
			(response) => {
				commit("setCategoriesPreferredJoined", response.data);
				commit("filterCategoriesPreferred");
			},
			(error) => {
				commit("setErrors", error.response.data.errors);
			}
		);
	},

	async getCategoriesPreferred({ commit }) {
		let url = window.Routes.getRoute("categories_preferred");
		await window.axios.get(url).then(
			(response) => {
				commit("setCategoriesPreferred", response.data);
			},
			(error) => {
				commit("setErrors", error.response.data.errors);
			}
		);
	},

	async categoriesSync({ commit }, category_ids) {
		return new Promise((resolve, reject) => {
			let url = window.Routes.getRoute("categories_sync");
			commit("syncCategoriesPreferredJoined", category_ids);
			commit("filterCategoriesPreferred");
			window.axios
				.post(url, {
					shop_categories: category_ids,
				})
				.then(
					(response) => {
						resolve(response.data);
					},
					(error) => {
						reject(error.response.data.errors);
						commit("setErrors", error.response.data.errors);
					}
				);
		});
	},
};

const mutations = {
	setCategories: (state, data) => {
		state.categories = data.data;
	},
	setCategoriesPreferredJoined: (state, data) => {
		state.categories_preferred_joined = data.data;
	},
	setCategoriesPreferred: (state, data) => {
		state.categories_preferred = data.data;
	},
	filterCategoriesPreferred: (state) => {
		state.categories_preferred = state.categories_preferred_joined.filter(
			(category) => {
				return category.selected;
			}
		);
	},
	syncCategoriesPreferredJoined: (state, category_ids) => {
		state.categories_preferred_joined.map((category) => {
			if (category_ids.includes(category.id)) {
				category.selected = true;
			} else {
				category.selected = false;
			}
		});
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
