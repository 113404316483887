export default function PhoneNumberMiddleware({ next }) {
  //If user is logged in
  if (localStorage.getItem("user")) {
    //Check if phone number is created
    if (JSON.parse(localStorage.getItem("user")).phone === null) {
      return next({
        name: "NumberPhone"
      });
    }
  }


  return next();
}
