<template>
  <div>
    <perfect-scrollbar :options="{wheelPropagation: false}"
      @ps-scroll-y="activeOrdersScrolled"
      @ps-y-reach-end="activeOrdersScrollEnded"
    >
      <slot></slot>

      <slot name="loading">
        <div class="loading" v-show="loading">
          <img src="@/assets/loading.gif" alt="Loading" />
        </div>
      </slot>
      <!-- /.loading -->
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "InfiniteScrolling",
  props: ["loading"],
  data() {
    return {
      scrolled: false,
      loading_ready: true,
    };
  },
  methods: {
    activeOrdersScrolled() {
      this.scrolled = true;
    },
    activeOrdersScrollEnded() {
      if (this.scrolled == true && this.loading_ready == true) {
        this.$emit("endReached", true);
        this.loading_ready = false;

        //Enable loading after .5s
        setTimeout(() => {
          this.loading_ready = true;
        }, 1000);
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.loading {
  text-align: center;
  padding: map-get($spacings, "default");
  img {
    height: 20px;
  }
}
</style>


