<template>
  <div class="NavButtonAddresses">
    <nav-button
      @click.stop=""
      v-bind:menuName="menuName"
      @navButton-clicked="navButtonClicked"
      v-bind:active="activeMenu == menuName"
      v-bind:arrowless="access_token == null"
    >
      <template v-slot:button>
        <div class="row g-0 justify-content-between">
          <div
            class="col-auto col-md-12 label font-size-sm color-twiks text-uppercase Aligner items-left"
          >
            {{ $t("addresses.title_singular") }}
          </div>
          <div
            class="col col-md-12 primaryLocation dotdotdot font-size-default lines-1"
            v-bind:class="{
              'font-weight-bold': access_token != null,
              'color-secondary': access_token == null,
            }"
          >
            {{ waitForPrimaryLocation }}
          </div>
        </div>
      </template>
      <template v-slot:dropdown>
        <div class="dropdown">
          <div class="title font-size-big pb-2 font-weight-bold">
            {{ $t("addresses.choose_your_location") }}
          </div>
          <perfect-scrollbar :options="{wheelPropagation: false}">
            <ul>
              <nav-button-addresses-radio-button
                v-for="location in addresses"
                :key="location.id"
                v-bind:location="location"
              />
            </ul>
          </perfect-scrollbar>
          <div class="clear"></div>
          <!-- /.clear -->

          <div class="w-100 float-left pt-2">
            <router-link
              class="addresses_link text-uppercase font-size-default font-weight-bold color-secondary hover-color-black"
              to="/addresses"
            >
              <u>{{ $t("addresses.manager_address_book") }}</u>
            </router-link>
          </div>
        </div>
      </template>
    </nav-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavButton from "./NavButton.vue";
import NavButtonAddressesRadioButton from "./NavButtonAddressesRadioButton.vue";
export default {
  name: "NavButtonAddresses",
  components: { NavButton, NavButtonAddressesRadioButton },
  props: ["activeMenu", "menuName"],
  methods: {
    ...mapActions(["getAddresses"]),
    navButtonClicked() {
      if (this.access_token != null) {
        this.$emit("navButton-clicked", this.menuName);
      }
    },
  },
  computed: {
    ...mapGetters(["access_token", "primaryAddress", "addresses"]),
    //Primary location
    waitForPrimaryLocation: function () {
      if (this.access_token != null) {
        if (this.primaryAddress != null) {
          return this.primaryAddress.address_description;
          // return (
          //   this.primaryAddress.country.name + ", " + this.primaryAddress.city
          // );
        } else {
          return this.$t("loading");
        }
      } else {
        return this.$t("addresses.login_to_set");
      }
    },
  },
  created() {
    //We get the addresses automatically on login and register so this checks if addresses are already loaded
    if (this.addresses.length == 0) {
      this.getAddresses();
    }
  },
};
</script>

<style lang='scss'>
.NavButtonAddresses {
  .navButton {
    .button {
      .buttonWrapper {
        &:before {
          transform: translateY(0%);
          top: auto;
          bottom: 5px;
        }

        .row {
          display: block;
          .label,
          .primaryLocation {
            max-width: 150px;
          }
        }
      }

      .dropdown {
        width: 400px;
      }
    }

    &.active {
      .button {
        .buttonWrapper {
          &:before {
            transform: translateY(0%) rotate(180deg);
            top: auto;
            bottom: 5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .NavButtonAddresses {
    width: 100%;
    .navButton {
      .button {
        min-height: 40px;
        padding-left: 0;
        background-color: map-get($colors, "lightGray");
        border-top: 1px solid map-get($defaults, "border-color");

        &:before,
        &:after {
          content: "";
          display: block;
          width: 100vw;
          height: calc(100% + 1px);
          background-color: map-get($colors, "lightGray");
          border-top: 1px solid map-get($defaults, "border-color");
          position: absolute;
          bottom: 0;
        }

        &:before {
          right: 100%;
        }
        &:after {
          left: 100%;
        }

        .buttonWrapper {
          width: 100%;

          .row {
            display: flex;
            .label {
              max-width: none;
            }
            .primaryLocation {
              padding-left: map-get($spacings, "default");
              text-align: right;
              max-width: none;
            }
          }
        }
      }

      .dropdown {
        max-width: none;
        width: 100%;

        .ps {
          max-height: 300px;
        }
      }
    }
  }
}
</style>
