export default function GuestAddressMiddleware({ next }) {
  if(localStorage.getItem('access_token') === null) {
    if (localStorage.getItem("guestAddress") === null) {
      return next({
        name: "Welcome"
      });
    } else {
      const guestAddress = JSON.parse(localStorage.getItem("guestAddress"));
      if(guestAddress.ready !== true){
        return next({
          name: "GuestAddressCreate"
        });
      }
    }
  }

  return next();
}
